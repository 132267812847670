import { Box, Button, Heading, Text, Icon, VStack, HStack, Divider, useColorModeValue } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getStandredSingleService } from '../../redux/app/action';

const StandredserviceOrderPlced = () => {
    const dispatch = useDispatch();
    const [singleData, setSingleData] = useState(null);
    let id = JSON.parse(localStorage.getItem("standredBookingId"));

    useEffect(() => {
        dispatch(getStandredSingleService(id))
            .then(res => {
                setSingleData(res.payload);
            });
    }, [id, dispatch]);

    // Use Chakra's color mode values for light and dark themes
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    const boxBgColor = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.700', 'gray.300');
    const buttonBgColor = useColorModeValue('teal.400', 'teal.600');
    const buttonTextColor = useColorModeValue('white', 'white');

    return (
        <Box 
            maxWidth="600px" 
            margin="auto" 
            padding="20px" 
            textAlign="center" 
            boxShadow="2xl" 
            borderRadius="lg" 
            bg={bgColor}
            mt="50px"
        >
            <VStack spacing={6}>
                <Icon as={CheckCircleIcon} boxSize={16} color="teal.500" />
                <Heading as="h1" fontSize="2xl" fontWeight="bold" color={textColor}>
                    Thank You for Your Order!
                </Heading>
                <Text fontSize="lg" color={textColor}>
                    Our technician will arrive promptly at your scheduled booking time.
                </Text>

                <Divider borderColor="gray.300" />

                <Box width="full" textAlign="left" bg={boxBgColor} p="6" borderRadius="lg" boxShadow="md">
                    <Heading as="h2" fontSize="lg" fontWeight="semibold" mb={4} color={textColor}>
                        Order Details
                    </Heading>
                    <VStack spacing={3} align="start">
                        <HStack>
                            <Text fontWeight="bold" color={textColor}>Name:</Text>
                            <Text color={textColor}>{singleData?.name}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" color={textColor}>City:</Text>
                            <Text color={textColor}>{singleData?.city}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" color={textColor}>Brand:</Text>
                            <Text color={textColor}>{singleData?.brand}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" color={textColor}>Pin Code:</Text>
                            <Text color={textColor}>{singleData?.pinCode}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" color={textColor}>Type of Cycle:</Text>
                            <Text color={textColor}>{singleData?.typeOfCycle}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" color={textColor}>Timings:</Text>
                            <Text color={textColor}>{singleData?.timings}</Text>
                        </HStack>
                    </VStack>
                </Box>

                <Link to="/">
                    <Button bg={buttonBgColor} color={buttonTextColor} size="lg" width="full" mt={6} _hover={{ bg: 'teal.500' }}>
                        Go to Home
                    </Button>
                </Link>
            </VStack>
        </Box>
    );
};

export default StandredserviceOrderPlced;
