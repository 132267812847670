import { Box, Heading, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, Image, IconButton } from '@chakra-ui/react'
import { IoMdCloseCircleOutline } from "react-icons/io";
import React, { useState } from 'react'

const ElevatingRides = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (src) => {
    setSelectedImage(src);
    onOpen();
  };

  return (
    <>
      <Box bgColor={"#fafafa"} border={"2px solid transparent"} width={["","","","95%"]} margin={"auto"} boxSizing='border-box' padding={["0px","0px","50px 170px","10px 170px"]} mt={"100px"} mb={["0px","0px","200px","200px"]}>
        <Heading p={["40px 0px","","","100px 0px"]} fontWeight={"400"} fontSize={["20px","","","50px"]}>
          <span style={{color:"gray"}}>Transform Your Ride</span>: Discover the Cycle <br /> Restore Advantage Today!
        </Heading>
        <Box position={["","","","relative"]} bgColor={"lightgray"} border={"20px solid transparent"} height={["auto","auto","700px","700px"]}>
          <Box>
            <SimpleGrid position={["","","","absolute"]} top={["","","","-50px"]} left={["0px","","","80px"]} width={["100%","100%","85%","85%"]} m={"auto"} columns={[2, 2, 3, 3]} spacing={["20px","","","30px"]}>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://t3.ftcdn.net/jpg/02/65/37/82/240_F_265378248_aJINj4qjcTIDDU0Y3D0agHPAaRooKmsl.jpg")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://t3.ftcdn.net/jpg/02/65/37/82/240_F_265378248_aJINj4qjcTIDDU0Y3D0agHPAaRooKmsl.jpg" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://images.unsplash.com/photo-1607109181641-74f8e7f4eb11?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmlrZSUyMHJlcGFpcnxlbnwwfHwwfHx8MA%3D%3D")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://images.unsplash.com/photo-1607109181641-74f8e7f4eb11?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmlrZSUyMHJlcGFpcnxlbnwwfHwwfHx8MA%3D%3D" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://images.unsplash.com/reserve/2jEqzi01Ru2HBhGLTBgB_MG_5236.jpg?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YmlrZSUyMHJlcGFpcnxlbnwwfHwwfHx8MA%3D%3D")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://images.unsplash.com/reserve/2jEqzi01Ru2HBhGLTBgB_MG_5236.jpg?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YmlrZSUyMHJlcGFpcnxlbnwwfHwwfHx8MA%3D%3D" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://images.unsplash.com/photo-1592222166121-93437e78d8d0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YmlrZSUyMHJlcGFpcnxlbnwwfHwwfHx8MA%3D%3D")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://images.unsplash.com/photo-1592222166121-93437e78d8d0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YmlrZSUyMHJlcGFpcnxlbnwwfHwwfHx8MA%3D%3D" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://t4.ftcdn.net/jpg/04/40/06/63/240_F_440066325_J0tPO2aBcgyNuDK6rMwQmekS6ZBfFzeO.jpg")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://t4.ftcdn.net/jpg/04/40/06/63/240_F_440066325_J0tPO2aBcgyNuDK6rMwQmekS6ZBfFzeO.jpg" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://images.unsplash.com/photo-1603981696711-bbbd734d1606?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGJpa2UlMjByZXBhaXJ8ZW58MHx8MHx8fDA%3D")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://images.unsplash.com/photo-1603981696711-bbbd734d1606?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGJpa2UlMjByZXBhaXJ8ZW58MHx8MHx8fDA%3D" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://images.unsplash.com/photo-1607109120350-b0adef8aa4db?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzF8fGJpa2UlMjByZXBhaXJ8ZW58MHx8MHx8fDA%3D")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://images.unsplash.com/photo-1607109120350-b0adef8aa4db?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzF8fGJpa2UlMjByZXBhaXJ8ZW58MHx8MHx8fDA%3D" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://t4.ftcdn.net/jpg/02/50/89/11/240_F_250891155_4WOFRZh7ITa4skGs4z5Gu3rcxiCPBCh6.jpg")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://t4.ftcdn.net/jpg/02/50/89/11/240_F_250891155_4WOFRZh7ITa4skGs4z5Gu3rcxiCPBCh6.jpg" alt="" />
              </Box>
              <Box bg='tomato' height='250px' onClick={() => handleImageClick("https://images.unsplash.com/photo-1533809308837-0b43c5a09839?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTJ8fGJpa2UlMjByZXBhaXJ8ZW58MHx8MHx8fDA%3D")}>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://images.unsplash.com/photo-1533809308837-0b43c5a09839?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTJ8fGJpa2UlMjByZXBhaXJ8ZW58MHx8MHx8fDA%3D" alt="" />
              </Box>

            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <Box position="absolute" top={4} right={4}>
              <IconButton aria-label="Close" icon={<IoMdCloseCircleOutline size={"40px"} />} onClick={onClose} />
            </Box>
            <Image src={selectedImage} alt="Selected" width="100vw" height="100vh" objectFit="contain" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ElevatingRides
