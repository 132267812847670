import React, { useState } from "react";
import { Box, Heading,Text } from "@chakra-ui/react";
import theme from "../../theme";

const ServiceCard = () => {



  return (
    <>
        <Box  boxSizing="border-box" mt={["","","","","40px"]}  >
            <Heading fontWeight={700} fontSize={["40px","","","50px"]}>Our Services</Heading>
            <Text fontFamily={theme.fonts.body} fontWeight={500} fontSize={["16px","16px","30px","30px","30px"]}>Personalized packages for you.</Text>
        </Box>
    </>
  ); 
};

export default ServiceCard;
