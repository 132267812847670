import { Box, Heading, Input, Stack, Textarea,Link,Text } from '@chakra-ui/react'
import React from 'react'
import MailUs from "../../components/ServiceRelated/MailUs"
import MetaData from '../../components/CustomComp/MetaData'
const contact= () => {
  return (
    <>
    <MetaData
  title="Contact Us - CycleRestore"
  description="CycleRestore is a web app that helps users repair their bicycles, providing a range of services to clients related to bicycles."
  keywords="bicycle repair, bike services, cycle repair service, CycleRestore"
 />

      <Box>
          <Box border={"2px solid transprent"} display={["","","flex","flex"]} boxSizing='border-box' p={["20px","20px","80px 180px","80px 180px"]}>
                <Box width={["100%","100%","50%","50%"]} border={"2px solid transprent"}>
                <Box  mb={["30px","30px","",""]}>
      
      <iframe
        className="google-map" // Apply className for styling
       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.905104107!2d78.24323674864048!3d17.412608636279675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1725448448092!5m2!1sen!2sin"
        allowFullScreen=""
        loading="lazy"
        title="Google Map"
        width="90%"
        height="440px"
      ></iframe>
    </Box>

                </Box>
                <Box width={["100%","100%","50%","50%"]} border={"2px solid transprent"} mt={["","","0px","0px"]}>
                  <Heading fontWeight={500} fontSize={"50px"}>Contact Us</Heading>
                 <Stack spacing={"20px"}>
                  <Text fontWeight={"300"} fontSize={"30px"}><Link href="tel:918977509088">Call Us : 8977509088</Link></Text>
                 <Input type='text' placeholder='name'/>
                  <Input type='number' placeholder='phone'/>
                  <Input type='text' placeholder='subject'/>
                  <Textarea type='text' placeholder='name'/>
                  <Input bgColor={"black"} color={"white"} type='submit'/>
                 </Stack>
                </Box>
          </Box>
      </Box>

      <MailUs/>
    </>
  )
}

export default contact