import { Box, Grid, GridItem,Text } from '@chakra-ui/react'
import React from 'react'
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { Link } from 'react-router-dom';
import logo from "../../assests/CycleUpdated.png"

const Footer = () => {
  return (
    <>
        <Box position={"relative"} border={"2px solid transprent"} bgColor={"black"} color={"white"} boxSizing='border-box'  padding={["20px","","","50px 100px"]} mt={"100px"}>
            <Box border={"2px solid transprent"}>
            <Grid
                h='auto'
                templateColumns={["repeat(1,1fr)","repeat(1,1fr)","repeat(5,1fr)","repeat(5,1fr)"]}
                gap={["4","4","4","4"]}
                >
                <GridItem  colSpan={["1","1","2","2"]} textAlign={"left"} boxSizing='border-box' padding={["","","","0px 40px"]}>
                    <img style={{width:"130px", margin:"10px 0px", padding:"0"} } src={logo} alt="" />
                    <Text fontSize={"17px"} fontWeight={"200"}>Cycle Restore offers expert cycle repair and maintenance services to keep your bike in top condition. From routine check-ups to major overhauls, our team ensures your ride performs flawlessly.</Text>
                </GridItem>
                <GridItem  colSpan={1} textAlign={"left"} >
                    <Text fontSize={"25px"} fontWeight={"200"} mb={"10px"}>SERVING IN</Text>
                    <ul>
                        <li style={{marginBottom:"8px"}}>Visakhapatnam</li>
                        <li style={{marginBottom:"8px"}}>Delhi</li>
                        <li style={{marginBottom:"8px"}}>Hyderabad</li>
                        <li style={{marginBottom:"8px"}}>Chennai</li>
                        <li style={{marginBottom:"8px"}}>Coimbatore</li>
                        <li style={{marginBottom:"8px"}}>Pune</li>
                    </ul>
                </GridItem>
                <GridItem  colSpan={1} textAlign={"left"} >
                <Text fontSize={"25px"} fontWeight={"200"} mb={"10px"}>QUICK LINKS</Text>
                    <ul>
                        <Link to={"/privacy-policy"}><li style={{marginBottom:"8px"}}>Privacy Policy</li></Link>
                        <Link to={"/refund-policy"}><li style={{marginBottom:"8px"}}>Refund Policy</li></Link>
                        <Link to={"/shipping-policy"}><li style={{marginBottom:"8px"}}>Shipping Policy</li></Link>
                        <Link to={"/terms-and-conditions"}><li style={{marginBottom:"8px"}}>Terms And Conditions</li></Link>
                        
                    </ul>
                </GridItem>
                <GridItem  colSpan={1} textAlign={"left"} >
                <Text fontSize={"25px"} fontWeight={"200"} mb={"10px"}>CONTACT</Text>
                    <Box>
                        <Box mb={"10px"} display={"flex"} gap={"10px"}>
                            <FaPhone size={"17px"}/>
                            <a href="tel:+918977509088"><Text>8977509088</Text></a>  
                        </Box>
                        <Box mb={"10px"} display={"flex"} gap={"10px"}>
                            <MdEmail size={"17px"}/>
                            <a href="mailto:contact@cyclestore.com"><Text>contact@cyclestore.com</Text></a>
                            
                        </Box>
                        <Box display={"flex"} gap={"10px"}>
                            <MdLocationOn size={"17px"}/>

                            <Text>Hyderabad , Telangana , india</Text>
                        </Box>
                       
                    </Box>
                </GridItem>
                </Grid>
            </Box>
            <hr style={{margin:"20px 0px"}}/>
            <Text>Copyright &#169; 2024, Cyclerestore Services. All Rights Reserved.</Text>
            <Text
        fontSize="lg"
        fontStyle="italic"
        textAlign="center"
        color="white"
        fontFamily="'Courier New', monospace"
       
        pl={7}
        m={2}
        borderColor="teal.500"
        _hover={{
          transform: 'scale(1.1)',
          transition: 'transform 0.3s ease',
        }}
        _active={{
          transform: 'scale(0.9)',
        }}
      >
        "Made in &#10084; Love with &#x1F1EE;&#x1F1F3; India"
      </Text>
            <Box position={"fixed"} bottom={10} right={10} >
            <a href="https://wa.me/918977509088">
        <img width={"50px"} src="https://github.com/saikiran11461/billing-app/assets/70562454/7854fc7c-6d92-4220-910c-f05105f68596" alt="" />
    </a> 
            </Box>
        </Box>
    </>
  )
}

export default Footer