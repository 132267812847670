import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom"
import { ChakraProvider } from '@chakra-ui/react';
import {Provider} from "react-redux"
import { store } from './redux/store';
import theme from './theme';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter>
      <ChakraProvider theme={theme}>
         <Provider store={store}>
            <App />
         </Provider>
      </ChakraProvider>
   </BrowserRouter>

);

reportWebVitals();
