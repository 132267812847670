import React, { useState } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Accordion,
  Box,
  Heading,
  Text,
  VStack,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { LiaRupeeSignSolid } from "react-icons/lia";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


library.add(faXmark);

const ServiceCard = ({
  index,
  expandedIndex,
  onAccordionChange,
  title,
  description,
  price,
  features,
  extraFeatures,
  link,
  buttonText,
  view,
  iconType,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    onAccordionChange(index);
  };

  const renderIcon = (included) => {
    const iconSize = "22px";

    return included ? (
      <CheckCircleOutlineOutlinedIcon sx={{ color: "green", fontSize: iconSize }} />
    ) : (
      <FontAwesomeIcon icon="xmark" color="red" style={{ fontSize: iconSize }} />
    );
  };

  return (
    <Box
      bg="black"
      color="white"
      borderRadius="10px"
      boxSizing="border-box"
      padding="50px 40px"
      height={isExpanded ? "auto" : "650px"}
      overflow="hidden"
      _hover={{
        cursor: "pointer",
        bgColor: "#90a4ae",
        color: "black",
      }}
    >
      <Heading fontSize="30px" textAlign="left" fontWeight={400}>
        {title}
      </Heading>
      <Box>
        <Text color="#F5F7F8" fontWeight={500} m="20px 10px" textAlign="left">
          {description}
        </Text>
      </Box>
      <hr style={{ width: "80%", marginTop: "20px", marginBottom: "20px" }} />
      <Box display="flex" alignItems="center">
        <LiaRupeeSignSolid size="30px" />
        <Heading fontSize="50px" textAlign="left">
          {price}
        </Heading>
      </Box>
      <hr style={{ width: "80%", marginTop: "20px", marginBottom: "20px" }} />
      <Box>
        {features.map((feature, idx) => (
          <Box display="flex" alignItems="center" key={idx}>
            {renderIcon(true)}
            <Text fontSize="15px" mb="3px" ml="5px" textAlign="left">
              {feature}
            </Text>
          </Box>
        ))}
        <Accordion allowToggle mt="20px" mb="40px">
          <AccordionItem isExpanded={expandedIndex === index}>
            <h2>
              <AccordionButton onClick={handleToggle}>
                <Box as="span" flex="1" textAlign="center">
                  View {view} more
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <VStack spacing={1} align="stretch">
                {extraFeatures.map((feature, idx) => (
                  <Box display="flex" alignItems="center" key={idx}>
                    {renderIcon(feature.included)}
                    <Text fontSize="15px" mb="3px" ml="5px" textAlign="left">
                      {feature.text}
                    </Text>
                  </Box>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
      <Link to={link}>
        <Button padding="30px 50px" m="0px 0px">
          {buttonText}
        </Button>
      </Link>
      <Box>
        <Text m={"15px 0px"} textAlign={"center"} fontSize="11px">
          * Any spare part charges will be extra as per MRP <br />
          * (Applicable for Non-Geared, Geared, Electric, and Road Bikes)
        </Text>
      </Box>
    </Box>
  );
};

export default ServiceCard;
