import { Box, Heading, SimpleGrid,Text} from '@chakra-ui/react'
import React from 'react'

const StatsSection = () => {
  return (
    <>
        <Box bgColor={"black"} width={"100%"} border={"2px solid transprent"} height={"auto"} boxSizing='border-box' padding={["20px","20px","70px 200px","70px 200px"]} m={["50px 0px","50px 0px","120px 0px","120px 0px"]}>

                <SimpleGrid alignItems={"center"} alignContent={"center"} columns={[1,1,3, 3]} spacing='40px'  border={"2px solid transprent"}>
                        <Box textAlign="center" >
                            <Heading color={'white'} fontSize={["","","","50px"]}>20+</Heading>
                            <Text color={"white"} fontSize={["","","","20px"]}>Proffessinal Technicinas </Text>
                        </Box>
                        <Box textAlign="center">
                        <Heading color={'white'} fontSize={["","","","50px"]}>200+</Heading>
                            <Text color={"white"} fontSize={["","","","20px"]}>Service Delvered </Text>
                        </Box>
                        <Box textAlign="center">
                        <Heading color={'white'} fontSize={["","","","50px"]}>5+</Heading>
                            <Text color={"white"} fontSize={["","","","20px"]}>Top Cities </Text>
                        </Box>
                </SimpleGrid>   
        </Box>
    </>
  )
}

export default StatsSection