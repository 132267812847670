import { Box, Text, Stack, Divider } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvancedSingleService } from '../../redux/app/action';
import { useParams } from 'react-router-dom';
import {  SyncLoader } from 'react-spinners';

const AdvancedSinglePage = () => {
  const dispatch = useDispatch();
  const single = useSelector((state) => state.appReducer.singleAdvancedService);
  const { id } = useParams();
  const loading = useSelector((state)=>state.appReducer.isLoading);
  useEffect(() => {
    dispatch(getAdvancedSingleService(id));
  }, [dispatch, id]);

  // console.log("advnaced single", single);

  return (
    <>
        {loading && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex={9999}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SyncLoader height={4} width={4} color="black" />
        </Box>
      )}
    <Box p={2} display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      
      <Box
        border="2px solid #e2e8f0"
        borderRadius="md"
        boxShadow="lg"
        p={1}
        bg="white"
        maxWidth="600px"
        width="100%"
      >
        <Stack spacing={1}>
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Name:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.name}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Email:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.email}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Phone:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.number}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">PinCode:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.pinCode}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Brand:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.brand}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">City:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.city}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Type Of Cycle:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.typeOfCycle}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Timings:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.timings}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Date:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.date}</Text>
          </Box>
          <Divider />
          <Box>
            <Text fontSize={["18px","18px","2xl","2xl"]} fontWeight="bold">Address:</Text>
            <Text ontSize={["16px","16px","2xl","2xl"]}>{single.address}</Text>
          </Box>
        </Stack>
      </Box>
    </Box>
    </>
  );
};

export default AdvancedSinglePage;
