import React from 'react'
import BasicServiceForm from "../../components/BasicServices-Component/BasicServiceForm"
import BasicServiceHeader from "../../components/BasicServices-Component/BasicServiceHeader"
const BasicService = () => {
  return (
    <>
        <BasicServiceHeader/>
        <BasicServiceForm/>

    </>
  )
}

export default BasicService