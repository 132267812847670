import React from 'react'
import AdvancedServiceHeader from '../../components/AdvancedService-Components/AdvancedServiceHeader'
import AdvancedServiceForm from '../../components/AdvancedService-Components/AdvancedServiceForm'

const AdvancedService = () => {
  return (
    <>
        <AdvancedServiceHeader/>
        <AdvancedServiceForm/>
    </>
  )
}

export default AdvancedService