import React from 'react'
import { Box, Heading,Stack,Text, } from '@chakra-ui/react'
const termsAndCondtions = () => {
  return (
    <>
      <Box border={"2px solid transprent"} boxSizing='border-box' padding={["10px","10px","20px","20px"]}>
            <Box border={"2px solid transprent"}>
                <Box width={["100%","100%","90%","90%"]} margin={"auto"} height={"400px"}>
                <div style={{ position: "relative", height: "100%", width: "100%" }}>
  <img
    style={{
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "10px",
    }}
    src="https://images.unsplash.com/photo-1623216216626-f8bfd191552d?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    alt=""
  />
  <Box
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      backgroundColor="rgba(0, 0, 0, 0.5)" 
      borderRadius="10px"
      color="white"
      boxSizing="border-box"
      padding={["10px", "", "", "20px"]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading fontSize={["30px", "30px", "60x", "60px"]} textAlign="center">
      Our Satisfaction Guarantee and Refund Policy
      </Heading>
      <Text fontSize={["18px","18px","22px","22px"]} textAlign="center" mt={4}>
      Your satisfaction is our priority. If you're not delighted with our service, let us know, and we'll
make it right or provide a refund
      </Text>
    </Box>
</div>

                </Box>
            </Box>

            <Box textAlign={"left"} width={["","","","80%"]} m={"auto"} mt={"50px"}>
                  <Stack p={5}>
                  <Box p={[4, 8, 12, 16]} bg="gray.100" borderRadius="lg">
      <Heading as="h1" size="lg" mb={4}>
        Welcome to Cycle Store!
      </Heading>
      <Text mb={8}>
        We are delighted to offer you our fitness equipment servicing and repair
        services. Before availing of our services, please carefully review the
        following Terms and Conditions.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Acceptance of Terms:
      </Heading>
      <Text mb={8}>
        By utilizing the Cycle Store website, mobile application, or engaging
        with our services, you acknowledge and agree to comply with these Terms
        and Conditions. If you disagree with any part of these terms, please
        refrain from using our services.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Service Overview:
      </Heading>
      <Text mb={8}>
        Cycle Store provides fitness equipment servicing, maintenance, repair,
        and installation services to individual consumers and businesses.
        Service details, including pricing, will be communicated to you upon
        request or during the booking process.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Booking and Scheduling:
      </Heading>
      <Text mb={8}>
        To secure an appointment, a non-refundable deposit of 20-30% of the
        service amount is required at the time of booking. If cancellation
        occurs before the scheduled service day, a full refund will be issued.
        However, if cancellation takes place after our technician has commenced
        travel for the service visit, there will be no refund, or any applicable
        traveling charges will be deducted from the refunded amount.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Service Fees and Payment:
      </Heading>
      <Text mb={8}>
        All service fees are outlined in our pricing structure, subject to
        change. Payment is due in full before or at the time of service. The
        initial deposit is deducted from the total service amount.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Cancellation and Rescheduling:
      </Heading>
      <Text mb={8}>
        Customers may cancel or reschedule a service appointment with a full
        refund if done before the scheduled service day. If cancellation occurs
        after our technician has started traveling for the visit, no refund
        will be provided, or traveling charges may be deducted from the refund.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Warranty and Guarantees:
      </Heading>
      <Text mb={8}>
        Cycle Store offers a limited warranty on services. Details are provided
        in the service documentation. Refer to our warranty policy for
        additional information.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Customer Responsibilities:
      </Heading>
      <Text mb={8}>
        Customers must provide accurate information during booking, ensure
        access to the equipment for servicing, and comply with any pre-service
        requirements communicated by Cycle Store.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Liability:
      </Heading>
      <Text mb={8}>
        While we strive to deliver high-quality services, Cycle Store is not
        liable for damages, losses, or injuries arising from service usage.
        Refer to our liability policy for further details.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Privacy Policy:
      </Heading>
      <Text mb={8}>
        We value your privacy. Our Privacy Policy details how we collect, use,
        and protect your personal information. By using our services, you agree
        to our Privacy Policy.
      </Text>

      <Heading as="h2" size="md" mb={4}>
        Modifications to Terms:
      </Heading>
      <Text mb={8}>
        Cycle Store reserves the right to update or modify these Terms and
        Conditions at any time. Changes become effective immediately upon
        posting on our website. It is your responsibility to periodically
        review these terms for updates.
      </Text>

      <Text textAlign="center" mb={4}>
        For any questions or concerns about these Terms and Conditions, please
        contact us at{" "}
        <Text as="a" color="blue.500" href="mailto:contact@cyclestore.com">
          contact@cyclestore.com
        </Text>
        .
      </Text>

      <Text textAlign="center" mb={4}>
        Thank you for choosing Cycle Store. We look forward to providing you
        with exceptional service!
      </Text>
    </Box>
                  </Stack>
            </Box>
      </Box>
    </>
  )
}

export default termsAndCondtions