import React, { useState } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import ServiceCard from "./SeriviceCard"; 

const Cards = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const basicServiceFeatures = [
    "Comprehensive cycle inspection",
    "Chain and cable lubrication",
    "Cleaning of gears, chain, cranks",
    "Headset and handlebar alignment",
  ];

  const basicExtraFeatures = [
    { text: "Tightening of all screws and bolts", included: true },
    { text: "Brake tuning and adjustment", included: true },
    { text: "Gear tuning and adjustment", included: true },
    { text: "Puncture repairs and tire inflation", included: true },
    { text: "Bottom bracket check and tightening", included: true },
    { text: "Wipe-down cleaning of the entire cycle", included: true },
    { text: "Dismantling of wheels, brakes, and chain", included: false },
    { text: "Degreasing of chain, gears, and cranks (drivetrain)", included: false },
    { text: "Wheel truing for smooth alignment", included: false },
    { text: "Full cycle wash with soap and water", included: false },
  ];

  const standardExtraFeatures = [
    { text: "Tightening of all screws and bolts", included: true },
    { text: "Brake tuning and adjustment", included: true },
    { text: "Gear tuning and adjustment", included: true },
    { text: "Puncture repairs and tire inflation", included: true },
    { text: "Dismantling of wheels, brakes, and chain", included: true },
    { text: "Degreasing of chain, gears, and cranks (drivetrain)", included: true },
    { text: "Wheel truing for smooth alignment", included: true },
    { text: "Full cycle wash with soap and water", included: true },
    { text: "Front and rear hub checkup and lubrication", included: false },
    { text: "Headset checkup and lubrication", included: false },
    { text: "Thorough cleaning of the cycle with cleanser and water", included: false },
  ];

  const advancedExtraFeatures = [
    { text: "Tightening of all screws and bolts", included: true },
    { text: "Brake tuning and adjustment", included: true },
    { text: "Gear tuning and adjustment", included: true },
    { text: "Puncture repairs and tire inflation", included: true },
    { text: "Dismantling of wheels, brakes, and chain", included: true },
    { text: "Degreasing of chain, gears, and cranks (drivetrain)", included: true },
    { text: "Wheel truing for precise alignment", included: true },
    { text: "Full cycle wash with soap and water", included: true },
    { text: "Front and rear hub checkup and lubrication", included: true },
    { text: "Headset checkup and lubrication", included: true },
    { text: "Thorough cleaning of the cycle with cleanser and water", included: true },
  ];

  const handleAccordionChange = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Box
      height="auto"
      border="2px solid transparent"
      width="100%"
      boxSizing="border-box"
      padding={["0px 12px", "0px 10px", "0px 0px", "50px 140px"]}
      mb={["30px", "20px", "20px", "0px"]}
      mt={0}
    >
    
      <SimpleGrid columns={[1, 2, 2, 3]} spacing="20px">
        <ServiceCard
          index={0}
          expandedIndex={expandedIndex}
          onAccordionChange={handleAccordionChange}
          title="Basic Service"
          description="Ideal for cycles in regular use with periodic maintenance Every 3-4 Months"
          price="799"
          features={basicServiceFeatures}
          extraFeatures={basicExtraFeatures}
          link="/basic-service"
          buttonText="Book Now"
          view="6"
          iconType="circle"
        />
        <ServiceCard
          index={1}
          expandedIndex={expandedIndex}
          onAccordionChange={handleAccordionChange}
          title="Standard Service"
          description="Recommended for cycles not in use or serviced for over 4-6 months"
          price="999"
          features={basicServiceFeatures}
          extraFeatures={standardExtraFeatures}
          link="/standred-service"
          buttonText="Book Now"
          view="8"
          iconType="standard"
        />
        <ServiceCard
          index={2}
          expandedIndex={expandedIndex}
          onAccordionChange={handleAccordionChange}
          title="Advanced Service"
          description="Best suited for cycles not used for 8-10 months, requiring a complete overhaul."
          price="1299"
          features={basicServiceFeatures}
          extraFeatures={advancedExtraFeatures}
          link="/advanced-service"
          buttonText="Book Now"
          view="11"
          iconType="circle"
        />
      </SimpleGrid>
    </Box>
  );
};

export default Cards;
