import { Box, Heading,Stack,Text } from '@chakra-ui/react'
import React from 'react'

const privacypolicy = () => {
  return (
    <>
      <Box border={"2px solid transprent"} boxSizing='border-box' padding={"20px"}>
            <Box border={"2px solid transprent"}>
                <Box width={["","","90%","90%"]} margin={"auto"} height={"400px"}>
                <div style={{ position: "relative", height: "100%", width: "100%" }}>
  <img
    style={{
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "10px",
    }}
    src="https://images.unsplash.com/photo-1623216216626-f8bfd191552d?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    alt=""
  />
 <Box
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      backgroundColor="rgba(0, 0, 0, 0.5)" // Adjust opacity here
      borderRadius="10px"
      color="white"
      boxSizing="border-box"
      padding={["20px", "", "", "20px"]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading fontSize={["30px", "30px", "70x", "70px"]} textAlign="center">
        We care about your privacy
      </Heading>
      <Text fontSize={["18px","18px","22px","22px"]} textAlign="center" mt={4}>
        Your privacy is important to us at FixMyMill. We respect your privacy
        regarding any information we may collect from you across our website.
      </Text>
    </Box>
</div>

                </Box>
            </Box>

            <Box textAlign={"left"} width={["","","","80%"]} m={"auto"} mt={"50px"}>
                  <Stack p={5}>
                  <Box p={[4, 8, 12, 16]} bg="gray.100" borderRadius="lg">
      <Heading as="h1" size="lg" mb={4}>
        Welcome to Cycle-Store's Privacy Policy
      </Heading>
      <Text mb={4}>
        At Cycle-Store, we take your privacy seriously. This policy outlines
        how we collect, use, protect, and disclose your personal information
        when you visit our website and use our services related to cycle sales,
        repairs, and accessories.
      </Text>
      <Heading as="h2" size="md" mb={2}>
        What information do we collect?
      </Heading>
      <Text mb={4}>
        When you interact with Cycle-Store, we may collect certain personal
        information from you, such as your name, email address, phone number,
        and payment details. This information allows us to provide you with our
        services effectively.
      </Text>
      <Heading as="h2" size="md" mb={2}>
        Purpose of Data Collection:
      </Heading>
      <Text mb={4}>
        We collect your data for specific purposes, including processing orders,
        scheduling repairs, and communicating with you regarding your purchases
        or service requests.
      </Text>
      <Heading as="h2" size="md" mb={2}>
        Data Protection:
      </Heading>
      <Text mb={4}>
        Your data is used solely for the purpose of providing the services you
        requested. We implement robust security measures to protect your
        information, including encryption and secure payment processing.
      </Text>
      <Heading as="h2" size="md" mb={2}>
        Third-Party Services:
      </Heading>
      <Text mb={4}>
        We may use trusted third-party services or tools to better understand
        user behavior. These services have their own privacy policies, which we
        encourage you to review.
      </Text>
      <Heading as="h2" size="md" mb={2}>
        Additional Information:
      </Heading>
      <Text>
        In addition to our privacy policy, we may provide additional
        information about warranties or guarantees specific to the products and
        services we offer. We hope this privacy policy provides you with a
        clear understanding of how we handle your data at Cycle-Store. Your
        privacy is important to us, and we are here to answer any questions or
        concerns you may have. Thank you for choosing Cycle-Store for your
        cycling needs.
      </Text>
    </Box>
                  </Stack>
            </Box>
      </Box>
    </>
  )
}

export default privacypolicy