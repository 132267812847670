import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './ScroolSlidercss.css';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Box, Button, Text, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function App() {
  return (
    <>
      <Box position="relative" width="100%" height="90vh">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img
              src="https://images.unsplash.com/photo-1497515098781-e965764ab601?q=80&w=3442&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="https://images.unsplash.com/photo-1499438182938-87d87862e154?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="https://images.unsplash.com/photo-1499438075715-fc23ef376ab9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="https://images.unsplash.com/photo-1496147539180-13929f8aa03a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fGJpY3ljbGV8ZW58MHx8MHx8fDA%3D"
              alt=""
            />
          </SwiperSlide>
        </Swiper>

        {/* Centered Flex Box for Text and Button */}
        <Flex
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
          color="white"
          zIndex={1}
          direction="column"
          align="center"
          textAlign="center"
          width={{ base: "80%", md: "60%", lg: "50%" }} // Set fixed width for larger screens
          px={4}
        >
          <Text
            fontWeight={700}
            fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "35px", xl: "40px" }} // Responsive font size
            whiteSpace={{ base: "wrap", sm: "wrap", md: "wrap", lg: "nowrap", xl: "nowrap"}} 
          >
            Refresh Your Ride with Premium Bicycle Service!
          </Text>
          <Link to="/services">
            <Button
              sx={{
                padding: "10px 12px",
                color: "black",
                bg: "white",
                borderRadius: "10px",
                mt: "20px",
                fontWeight: "bold",
              }}
            >
              Book now
            </Button>
          </Link>
        </Flex>
      </Box>
    </>
  );
}
