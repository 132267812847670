import React from 'react'
import ScroolSlider from '../../components/Sliders/ScroolSlider'
import Cards from "../../components/Header/Cards/Cards"
import ChooseUs from '../../components/Header/ChooseUs/ChooseUs'
import FreqntlyAskedQuestions from '../../components/Header/FrequntlyQuestions/FreqntlyAskedQuestions'
import ElavatingRides from '../../components/Header/ElavatingRides/ElavatingRides'
import StatsSection from '../../components/Header/StatsSection/StatsSection'
import BrandsMarquee from '../../components/Sliders/BrandsMarquee'
import CardsHeading from '../../components/Header/CardsHeading/CardsHeading'
import MetaData from '../../components/CustomComp/MetaData'
const home = () => {
  return (
    <>
      {/* <HeroParallax/> */}
      <MetaData
  title="Home - CycleRestore"
  description="Welcome to CycleRestore, your one-stop solution for bicycle repairs, maintenance, and services. Keep your bike in top shape with our expert care."
  keywords="bicycle repair, bike maintenance, cycle services, CycleRestore home"
 />


      <ScroolSlider/>
      <CardsHeading/>
      <Cards/>
      <ChooseUs/>
     <BrandsMarquee/>
      <ElavatingRides/>
      <StatsSection/>
      <FreqntlyAskedQuestions/>
    </>
  )
}

export default home