import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

const StandredServiceHeader = () => {
  return (
    <>
      <Box position={"relative"} border={"2px solid transprent"}>
        <Box
          height={["", "", "390px", "390px"]}
          width={["100%", "100%", "100%", "100%"]}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: "0.9",
            }}
            src="https://images.unsplash.com/photo-1497515098781-e965764ab601?q=80&w=3442&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
          />
          <Box
            textAlign={"left"}
            boxSizing="border-box"
            padding={[
              "0px 0px 0px 20px",
              "0px 0px 0px 20px",
              "0px 0px 0px 200px",
              "0px 0px 0px 200px",
            ]}
            color={"white"}
            position={"absolute"}
            top={"50px"}
          >
            <Heading fontSize={["30px", "30px", "50px", "50px"]}>
              Standred Plan
            </Heading>
            <hr />
            <Text color={"white"} fontWeight={500} fontSize={["60px", "60px", "90px", "90px"]}>
              {" "}
              &#8377;999
            </Text>
            <hr />
            <Text m={"20px 0px"} fontSize={["", "", "", "19px"]}>
              {" "}
              Our Standred plan, starting at ₹999,
              <br /> provides essential features to kickstart your journey
              towards success.
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StandredServiceHeader;
