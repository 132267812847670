import { Box, Heading,Text } from '@chakra-ui/react'
import React from 'react'
import StatsSection from '../../components/Header/StatsSection/StatsSection'
import MetaData from '../../components/CustomComp/MetaData'

const about = () => {
  return (
    <>
<MetaData
  title="About Us - CycleRestore"
  description="CycleRestore is a trusted platform offering professional bicycle repair and maintenance services, committed to ensuring smooth rides for cyclists everywhere."
  keywords="bicycle repair, bike services, cycle maintenance, about CycleRestore"
 />


        <Box border={"2px solid transprent"} bgColor={"lightgray"}>
            <Box  w={["","","70%","70%"]} m={"auto"} border={"2px solid transprent"} display={["","","flex","flex"]} boxSizing='border-box' padding={["40px","40px","100px 100px","150px 100px"]} gap={"60px"} >
              <Box textAlign={["left","left","left","left"]} width={["","","","40%"]} mt={["","","50px","50px"]}>
                  <Heading fontSize={"60px"}>About Us</Heading>
                  <Text fontSize={"15px"}>"Discover top-quality cycles and accessories at our store, where passion meets performance for every rider.</Text>
              </Box>
              <Box mt={["50px","","",""]} width={["","","","60%"]} textAlign={"left"}>
                <Text>Welcome to our cycle store, your go-to destination for premium bicycles and accessories. Whether you're a seasoned cyclist or just starting out, we offer a wide range of bikes to suit every need. Our expert staff is here to help you find the perfect ride for a smooth and enjoyable cycling experience</Text>
                    <br />
                <Text>We believe in the power of cycling to transform lives and promote a healthy lifestyle. Our high-quality gear and accessories are designed to enhance your ride, providing comfort, safety, and style. Join our community of cycling enthusiasts and experience the joy of cycling with us.</Text>
              </Box>
            </Box>
        </Box>

        <StatsSection/>
    </>
  )
}

export default about