import React from 'react'
import StandredServiceHeader from '../../components/StanderedService-Components/StandredServiceHeader'
import StandredServiceForm from '../../components/StanderedService-Components/StandredServiceForm'

const StanderedService = () => {
  return (
    <>
        <StandredServiceHeader/>
        <StandredServiceForm/>
    </>
  )
}

export default StanderedService