import React from 'react'
import { Text } from '@chakra-ui/react'
const CardsHeading = () => {
  return (
    <>
          <Text
        lineHeight={"40px"}
        fontWeight={600}
        fontSize={{ base: "30px", sm: "30px", md: "30px", lg: "35px", xl: "40px" }}
        whiteSpace={{ base: "wrap", sm: "wrap", md: "wrap", lg: "nowrap", xl: "nowrap" }}
        mb={["20px","20px","0px","0px","0px"]}
        mt={"45px"}
      >
      Cycle Restore Packages <br />
      
      <span style={{
  fontSize: '25px',
  fontWeight: '500',
  '@media (max-width: 600px)': {
    fontSize: '17px'
  }
}}>
  Custom Care for Every Ride
</span>

      </Text>
    </>
  )
}

export default CardsHeading