import React from 'react'
import {Box, Button, Heading,SimpleGrid,Text} from "@chakra-ui/react"
const OurFlatFrom = () => {
  return (
    <Box border={"2px solid transprent"} width={["90%","","","70%"]} m={"auto"} mt={"100px"} mb={"100px"}>
          <Box textAlign={"left"}>
              <Heading textAlign={"left"} fontWeight={"200"} fontSize={["35px","","","60px"]} m={["50px 0px","","","70px 0px"]}>One Destination, <br /> Limitless Rides For You</Heading>

            <SimpleGrid  columns={[1,1,3,3]} spacing='50px'>
              <Box  borderTop={"2px solid black"} boxSizing='border-box' padding={"30px 80px 50px 0px"}  height={["auto","auto","80px","80px"]}>
                <Heading fontWeight={"500"} m={"10px 0px"}  fontSize={["20px","","","20px"]}>Discover Your Perfect Ride</Heading>
                <Text fontSize={["","","","15px"]}>Explore our extensive range of bikes, catering to every cyclist's needs and adventures.</Text>
              </Box>
              <Box borderTop={"2px solid black"} boxSizing='border-box' padding={"30px 80px 20px 0px"}  height={["auto","auto","80px","80px"]}>
              <Heading fontWeight={"500"} m={"10px 0px"}  fontSize={["20px","","","20px"]}>Unmatched Quality and Variety</Heading>
                <Text fontSize={["","","","15px"]}>Find top brands and latest models in our one-stop shop, meeting diverse cycling needs."</Text>
              </Box>
              <Box borderTop={"2px solid black"} boxSizing='border-box' padding={"30px 80px 20px 0px"}  height={["auto","auto","80px","80px"]}>
              <Heading fontWeight={"500"} m={"10px 0px"}  fontSize={["20px","","","20px"]}>Endless Possibilities on Two Wheels</Heading>
                <Text fontSize={["","","","15px"]}>From rentals to repairs, our services offer cyclists diverse and enriching experiences.</Text>
              </Box>
            </SimpleGrid>

            <Button p={"30px 60px"} bgColor={"black"}  color={"white"} mt={["30px","","","120px"]}>Get Started</Button>
          </Box>
    </Box>
  )
}

export default OurFlatFrom

