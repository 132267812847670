import React, { useState } from 'react';
import { Box, Image, UnorderedList, ListItem, IconButton,  Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      width="100%"
      border="2px solid transparent"
      display="flex"
      justifyContent="space-between"
      boxSizing="border-box"
      padding="0px 40px"
      bg="black"
      alignContent="center"
      height="100px"
    >
      <Box border="2px solid transparent">
        <a href="/"><Image
          width="120px"
          src="https://github.com/saikiran11461/billing-app/assets/70562454/43aa1c84-d9c4-4b1e-b7a4-d5a99b401bfb"
          alt="Logo"
        /></a>
      </Box>
      <Box
        display={{ base: 'none', md: 'flex' }}
        alignItems="center"
        border="2px solid transparent"
      >
        <UnorderedList
          color="white"
          display="flex"
          alignItems="center"
          listStyleType="none"
          gap="25px"
          fontSize="17px"
          m={0}
          p={0}
        >
          <ListItem><Link  to="/" color="white">Home</Link></ListItem>
          <ListItem><Link  to="/services" color="white">Service</Link></ListItem>
          <ListItem><Link  to="/about" color="white">About us</Link></ListItem>
          <ListItem><Link  to="/contact" color="white">Contact us</Link></ListItem>
          {/* <ListItem><Link  to={token?"admin": "/api/admin/login"} color="white">{token?"Admin": "Admin Login"}</Link></ListItem> */}
        </UnorderedList>
      </Box>
      <Box
        display={{ base: 'flex', md: 'none' }}
        alignItems="center"
      >
        <IconButton
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Toggle Menu"
          onClick={toggleMenu}
          color="white"
          bg="black"
          _hover={{ bg: 'gray.700' }}
        />
      </Box>
      {isOpen && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100vh"
          bg="black"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          transition="opacity 0.3s ease-in-out"
          zIndex="10"
          opacity={isOpen ? 1 : 0}
        >
          <Box
            position="absolute"
            top="20px"
            right="20px"
          >
            <IconButton
              icon={<CloseIcon />}
              aria-label="Close Menu"
              onClick={toggleMenu}
              color="white"
              bg="black"
              _hover={{ bg: 'gray.700' }}
            />
          </Box>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <UnorderedList
              color="white"
              listStyleType="none"
              fontSize="24px"
              m={0}
              p={0}
              textAlign="center"
              spacing="20px"
            >
              <ListItem><Link to="/" color="white" onClick={toggleMenu}>Home</Link></ListItem>
              <ListItem><Link to="/services" color="white" onClick={toggleMenu}>Service</Link></ListItem>
              <ListItem><Link to="/about" color="white" onClick={toggleMenu}>About us</Link></ListItem>
              <ListItem><Link to="/contact" color="white" onClick={toggleMenu}>Contact us</Link></ListItem>
              {/* <ListItem><Link to={token?"admin": "/api/admin/login"} color="white">{token?"Admin": "Admin Login"}</Link></ListItem> */}
            </UnorderedList>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
