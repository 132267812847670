import * as types from "./actionTypes";
import axios from "axios";
 
 export const postBasicServiceData = (payload) => (dispatch) => {
    dispatch({ type: types.POST_BASIC_SERVICE_DATA_REQUEST });
  
 return axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/basic-service/addBasicServices", payload)
    .then(res => {
      console.log("res-basic-service", res)
     return dispatch({ type: types.POST_BASIC_SERVICE_DATA_SUCCESS, payload: res.data });
    })
    .catch(err => {
    return  dispatch({ type: types.POST_BASIC_SERVICE_DATA_FAILURE, payload: err.message || 'Something went wrong' });
    });
};  



 
export const postStandredServiceData = (payload) => (dispatch) => {
    dispatch({ type: types.POST_STANDRED_SERVICE_DATA_REQUEST });
  
 return axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/standred-service/addStandredServices", payload)
    .then(res => {
     return dispatch({ type: types.POST_STANDRED_SERVICE_DATA_SUCCESS, payload: res.data });
    })
    .catch(err => {
    return  dispatch({ type: types.POST_STANDRED_SERVICE_DATA_FAILURE, payload: err.message || 'Something went wrong' });
    });
};  



 
export const postAdvancedServiceData =(payload)=>(dispatch)=>{
      dispatch({type:types.POST_ADVANCED_SERVICE_DATA_REQUEST});
   return axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/advanced-service/addAdvancedServices",payload)
   .then(res=>{
    return  dispatch({type:types.POST_ADVANCED_SERVICE_DATA_SUCCESS, payload:res.data});
   })
   .catch(err=>{
   return   dispatch({type:types.POST_ADVANCED_SERVICE_DATA_FAILURE, payload:err || "Something went wrong"})
   })
} 



export const getBasicData = (city) => (dispatch) => {
   dispatch({ type: types.GET_BASIC_SERVICE_DATA_REQUEST });

   return axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/basic-service/addBasicServices/filters", city)
       .then(res => {
           console.log("Response received for basic service data:", res.data);
           dispatch({ type: types.GET_BASIC_SERVICE_DATA_SUCCESS, payload: res.data });
       })
       .catch(err => {
           console.error("Error fetching basic service data:", err);
           dispatch({ type: types.GET_BASIC_SERVICE_DATA_FAILURE, payload: err.message });
       });
};

 



export const getStandredData = (city)=>(dispatch)=>{
   dispatch({type:types.GET_STANDRED_DATA_REQUEST});
   return axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/standred-service/addStandredServices/filters", city)
   .then(res=>{
      return  dispatch({type:types.GET_STANDRED_DATA_SUCCESS, payload:res.data})
   })
   .catch(err=>{
      return  dispatch({type:types.GET_STANDRED_DATA_FAILURE, payload:err})
   })
}




export const getAdvancedData = (city)=>(dispatch)=>{
   dispatch({type:types.GET_ADVANCED_SERVICE_DATA_REQUEST});
   return axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/advanced-service/addAdvancedService/filters", city)
   .then(res=>{
   
      return  dispatch({type:types.GET_ADVANCED_SERVICE_DATA_SUCCESS, payload:res.data})
   })
   .catch(err=>{
      return  dispatch({type:types.GET_ADVANCED_SERVICE_DATA_FAILURE, payload:err})
   })
}


export const postLogin =(payload)=>(dispatch)=>{
      dispatch({type:types.POST_LOGIN_REQUEST});
    return  axios.post("https://cycle-store-backend-95449427771.asia-south1.run.app/api/admin/login", payload)
      .then(res=>{
       return  dispatch({type:types.POST_LOGIN_SUCCESS, payload:res.data})
      })
      .catch(err=>{
      return   dispatch({type:types.POST_LOGIN_FAILURE, payload:err})
      })
}


export const getBasicSingleService =(id)=>(dispatch)=>{
   // console.log("id action", id)
   dispatch({type:types.GET_SINGLE_BASIC_SERVICE_REQUEST});
 return  axios.get(`https://cycle-store-backend-95449427771.asia-south1.run.app/api/basic-service/${id}`)
   .then(res=>{
      console.log("response Basic action", res)
    return  dispatch({type:types.GET_SINGLE_BASIC_SERVICE_SUCCESS, payload:res.data})
   })
   .catch(err=>{
   return   dispatch({type:types.GET_SINGLE_BASIC_SERVICE_FAILURE, payload:err})
   })
}


export const getStandredSingleService =(id)=>(dispatch)=>{
   // console.log("id action", id)
   dispatch({type:types.GET_SINGLE_STANDRED_SERVICE_REQUEST});
 return  axios.get(`https://cycle-store-backend-95449427771.asia-south1.run.app/api/standred-service/${id}`)
   .then(res=>{
    return  dispatch({type:types.GET_SINGLE_STANDRED_SERVICE_SUCCESS, payload:res.data})
   })
   .catch(err=>{
   return   dispatch({type:types.GET_SINGLE_STANDRED_SERVICE_FAILURE, payload:err})
   })
}


export const getAdvancedSingleService =(id)=>(dispatch)=>{
   // console.log("id action", id)
   dispatch({type:types.GET_SINGLE_ADVANCED_SERVICE_REQUEST});
 return  axios.get(`https://cycle-store-backend-95449427771.asia-south1.run.app/api/advanced-service/${id}`)
   .then(res=>{
    return  dispatch({type:types.GET_SINGLE_ADVANCED_SERVICE_SUCCESS, payload:res.data})
   })
   .catch(err=>{
   return   dispatch({type:types.GET_SINGLE_ADVANCED_SERVICE_FAILURE, payload:err})
   })
}