import React from 'react';
import { Box, Image, HStack, useToken, Heading } from '@chakra-ui/react';
import urban from "../../assests/cycleBrands/urban.jpg";
import lifeLong from "../../assests/cycleBrands/lifelong.jpg";
import hercules from "../../assests/cycleBrands/herculesNew.jpg";
import cockaToa from "../../assests/cycleBrands/cocknewone.png";
import hero from "../../assests/cycleBrands/hero.png";
import fireFox from "../../assests/cycleBrands/firefox.png";

const brandLogos = [
  urban, lifeLong, hercules, cockaToa, hero, fireFox
];

const BrandsMarquee = () => {
  const [marqueeWidth] = useToken('sizes', ['full']); 

  return (
    <Box 
      overflow="hidden" 
      whiteSpace="nowrap" 
      border={"2px solid transparent"} 
      width={"90%"} 
      margin={"auto"} 
      mt={["", "", "", "50px"]}
    >
      <Heading 
        m={"50px 0px"} 
        fontWeight={"600"} 
        fontSize={["30px", "30px", "60px", "60px"]}
      >
        Brands We Serve
      </Heading>

      <Box 
        border={"2px solid transparent"} 
        width={"90%"} 
        margin={"auto"}
        as="div"
        display="inline-block"
        animation="marquee 15s linear infinite"
        _hover={{
          animationPlayState: 'paused',
        }}
      >
        <HStack spacing={8}>
          {/* Original set of images */}
          {brandLogos.map((logo, index) => (
            <Image
              key={index}
              src={logo}
              alt={`Brand ${index}`}
              width="200px" 
              height="150px" 
              objectFit="contain" 
              borderRadius="md" 
              boxShadow="sm" 
            />
          ))}
          {/* Duplicate set of images to create the continuous effect */}
          {brandLogos.map((logo, index) => (
            <Image
              key={index + brandLogos.length} // Ensure unique key for each image
              src={logo}
              alt={`Brand ${index + brandLogos.length}`}
              width="200px" 
              height="150px" 
              objectFit="contain" 
              borderRadius="20px" 
              boxShadow="sm" 
            />
          ))}
        </HStack>
      </Box>

      <style>
        {`
          @keyframes marquee {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default BrandsMarquee;
