export const POST_BASIC_SERVICE_DATA_REQUEST = 'POST_BASIC_SERVICE_DATA_REQUEST';
export const POST_BASIC_SERVICE_DATA_SUCCESS = 'POST_BASIC_SERVICE_DATA_SUCCESS';
export const POST_BASIC_SERVICE_DATA_FAILURE = 'POST_BASIC_SERVICE_DATA_FAILURE';



export const POST_STANDRED_SERVICE_DATA_REQUEST = 'POST_STANDRED_SERVICE_DATA_REQUEST';
export const POST_STANDRED_SERVICE_DATA_SUCCESS = 'POST_STANDRED_SERVICE_DATA_SUCCESS';
export const POST_STANDRED_SERVICE_DATA_FAILURE = 'POST_STANDRED_SERVICE_DATA_FAILURE';



export const POST_ADVANCED_SERVICE_DATA_REQUEST = 'POST_ADVANCED_SERVICE_DATA_REQUEST';
export const POST_ADVANCED_SERVICE_DATA_SUCCESS = 'POST_ADVANCED_SERVICE_DATA_SUCCESS';
export const POST_ADVANCED_SERVICE_DATA_FAILURE = 'POST_ADVANCED_SERVICE_DATA_FAILURE';




export const GET_BASIC_SERVICE_DATA_REQUEST = 'GET_BASIC_SERVICE_DATA_REQUEST';
export const GET_BASIC_SERVICE_DATA_SUCCESS = 'GET_BASIC_SERVICE_DATA_SUCCESS';
export const GET_BASIC_SERVICE_DATA_FAILURE = 'GET_BASIC_SERVICE_DATA_FAILURE';



export const GET_STANDRED_DATA_REQUEST = 'GET_STANDRED_DATA_REQUEST';
export const GET_STANDRED_DATA_SUCCESS = 'GET_STANDRED_DATA_SUCCESS';
export const GET_STANDRED_DATA_FAILURE = 'GET_STANDRED_DATA_FAILURE';




export const GET_ADVANCED_SERVICE_DATA_REQUEST = 'GET_ADVANCED_SERVICE_DATA_REQUEST';
export const GET_ADVANCED_SERVICE_DATA_SUCCESS = 'GET_ADVANCED_SERVICE_DATA_SUCCESS';
export const GET_ADVANCED_SERVICE_DATA_FAILURE = 'GET_ADVANCED_SERVICE_DATA_FAILURE';



export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE';





export const GET_SINGLE_BASIC_SERVICE_REQUEST = 'GET_SINGLE_BASIC_SERVICE_REQUEST';
export const GET_SINGLE_BASIC_SERVICE_SUCCESS = 'GET_SINGLE_BASIC_SERVICE_SUCCESS';
export const GET_SINGLE_BASIC_SERVICE_FAILURE = 'GET_SINGLE_BASIC_SERVICE_FAILURE';



export const GET_SINGLE_STANDRED_SERVICE_REQUEST = 'GET_SINGLE_STANDRED_SERVICE_REQUEST';
export const GET_SINGLE_STANDRED_SERVICE_SUCCESS = 'GET_SINGLE_STANDRED_SERVICE_SUCCESS';
export const GET_SINGLE_STANDRED_SERVICE_FAILURE = 'GET_SINGLE_STANDRED_SERVICE_FAILURE';


export const GET_SINGLE_ADVANCED_SERVICE_REQUEST = 'GET_SINGLE_ADVANCED_SERVICE_REQUEST';
export const GET_SINGLE_ADVANCED_SERVICE_SUCCESS = 'GET_SINGLE_ADVANCED_SERVICE_SUCCESS';
export const GET_SINGLE_ADVANCED_SERVICE_FAILURE = 'GET_SINGLE_ADVANCED_SERVICE_FAILURE';