const init ={

}

export const reducer =(oldState=init, action)=>{
        const {type} = action;

        switch(type){
            default:
                return oldState
        }
}