import { Accordion, Text, AccordionButton, AccordionItem, AccordionPanel, Box, AccordionIcon, Heading } from '@chakra-ui/react';
import React from 'react';

const FreqntlyAskedQuestions = () => {
  return (
    <>
      <Box border={"2px solid transparent"} width={["100%", "100%", "100%", "70%"]} margin={"auto"} mt={"60px"}>
        <Box textAlign={"left"} m={["10px 0px", "40px 0px", "50px 0px", "50px 0px"]}>
          <Text fontSize={"19px"} color={"gray"}>FAQ's</Text>
          <Heading fontWeight={"600"} fontSize={["30px", "30px", "40px", "50px"]}>Your Questions, Answered.</Heading>
        </Box>

        <Box border={"2px solid transparent"} width={"100%"}>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    1. Why Cycle Restore?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                Cycle Restore is dedicated to promoting sustainability and healthy living through expert bicycle maintenance and repair services. We prioritize prolonging the lifespan of bicycles to minimize waste and environmental impact. With Cycle Restore, cyclists can trust their bikes are in top condition for safe and enjoyable rides.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    2. How does Cycle Restore work?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                Cycle Restore operates through a convenient process: customers bring their bicycles to our workshop or schedule a pick-up service. Our skilled technicians assess the bicycle's condition, perform necessary repairs or maintenance, and ensure it meets safety standards. Once completed, customers can collect their bicycles or opt for delivery.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    3. Is Cycle Restore safe to use?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                Cycle Restore ensures safety by employing skilled technicians, adhering to strict quality standards, and using premium materials for bike maintenance and repair.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    4. What are the benefits of using Cycle Restore?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                Cycle Restore provides expert maintenance services to keep bicycles in top condition, promoting sustainability and reducing environmental impact. With convenient service options and rigorous quality standards, customers can trust their bikes are safe and reliable for every ride.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    5. How long does it take to see results with Cycle Restore?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                The turnaround time at Cycle Restore varies based on the complexity of the service needed. Typically, minor repairs and maintenance can be completed within a day, while more extensive work may take a few days. Our goal is to ensure timely service without compromising quality, so customers can get back on their bikes as soon as possible.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    6. Can Cycle Restore be used on all types of bicycles?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                Cycle Restore caters to all types of bicycles, including road bicycles, mountain bicycles, and hybrids, ensuring comprehensive servicing for diverse cycling needs. Our skilled technicians are experienced in handling various bicycle models and brands, guaranteeing top-quality maintenance and repair services.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    7. Does Cycle Restore require any special tools for installation?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                Cycle Restore technicians utilize a wide array of specialized tools tailored for bicycle maintenance and repair. These tools are essential for ensuring precise installation and proper adjustments to various bike components. Customers can trust that our skilled technicians are equipped with the necessary tools to handle any installation or repair job efficiently and effectively.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' fontSize={["16px", "16px", "18px", "23px"]} p={"10px 0px"} fontWeight={"500"} flex='1' textAlign='left'>
                    8. How do I avail Cycle Restore services?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={"18px"} fontWeight={"300"}>
                To engage with Cycle Restore's services, simply visit our website or contact us directly to schedule an appointment. Whether you need maintenance, repair, or customization for your bicycle, our team is ready to assist you. We offer flexible options for drop-off, pick-up, and delivery, ensuring a convenient experience tailored to your needs.
                company logo
                Your Trusted Fitness Equipment Service Provider across 25+ Indian Cities. Installation, Maintenance, Repair Services for Residential and Commercial Clients. Committed to Customer Satisfaction.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </>
  )
}

export default FreqntlyAskedQuestions;
