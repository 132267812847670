import React from 'react';
import { Helmet } from 'react-helmet';

const CycleRestorePage = () => {
  return (
    <div>
      <Helmet>
        <title>Cycle Restore | Restore Your Bicycle with Ease</title>
        <meta name="description" content="Restore your bicycle to its former glory with Cycle Restore. We offer professional services to fix, clean, and enhance your bike. Join us and ride smoothly again." />
        <meta property="og:title" content="Cycle Restore - Professional Bicycle Restoration" />
        <meta property="og:description" content="Looking to restore your bike? Cycle Restore offers comprehensive bike repair and maintenance services." />
        <meta property="og:image" content="https://yourdomain.com/your-image.jpg" />
        <meta property="og:url" content="https://www.cyclerestore.com/cycle-restore" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cycle Restore | Bicycle Restoration" />
        <meta name="twitter:description" content="Restore your bicycle with expert services at Cycle Restore." />
        <meta name="twitter:image" content="https://www.cyclerestore.com/your-image.jpg" />
        <link rel="canonical" href="https://www.cyclerestore.com/cycle-restore" />
        <meta name="keywords" content="bicycle restoration, cycle repair, bike cleaning, professional bike repair, Cycle Restore" />
      </Helmet>
      
      <h1>Welcome to Cycle Restore</h1>
      <p>Restore your bike with ease and professionalism.</p>
    </div>
  );
};

export default CycleRestorePage;
