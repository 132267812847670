export const radioButtonData = [
    {
      value:"09:00AM-11:00AM"
    },
    {
      value:"10:00AM-12:00PM"
    },

    {
     value: "11:00AM-01:00PM"
    },

    {
     value:"12:00PM-02:00PM"
    },

    {
      value:"01:00PM-03:00PM"
    },

    {
      value:"02:00PM-04:00PM"
    },
    {
      value:"03:00PM-05:00PM"
    },
    {
      value:"04:00PM-06:00PM"
    }
    
  ]