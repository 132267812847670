import { Box, Button, Heading,Input,Text } from '@chakra-ui/react'
import React from 'react'

const MailUs = () => {
  return (
    <>
         <Box textAlign={"center"} bgColor={"#f5f5f5"} border={"2px solid transprent"} width={["100%","100%","98%","98%"]} boxSizing='border-box' p={["10px 0px","10px 0px","60px","60px"]} margin={"auto"}>
                <Heading fontSize={["30px","30px","60px","60px"]} fontWeight={"300"}>Keep Updated <br />& Don’t Miss Anything!</Heading>
                <Text fontSize={["12px","12px","18px","18px"]} m={"20px"}>Stay informed, don't miss out! Subscribe for updates today.</Text>
                <form>
                    <Box textAlign={"center"} mt={["","","","40px"]} gap={3} display={"flex"} width={["80%","80%","50%","50%"]} margin={"auto"} alignContent={"center"} alignItems={"center"}>
                    <Input ml={["","","","40px"]} p={["","","","30px 60px"]} border={"2px solid grey"}  type='email' placeholder='Give us a mail'/>
                    <Button bgColor={"black"} color={"white"} p={["","","","30px 60px"]} type='submit'>Submit</Button>
                    </Box>
                    
                </form>
         </Box>
    </>
  )
}

export default MailUs