import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/home';
import About from '../pages/About/about';
import Chennai from '../pages/Chennai/chennai';
import Coimbatore from '../pages/Coimbatore/coimbatore';
import Contact from '../pages/Contact/contact';
import Delhi from '../pages/Delhi/delhi';
import Hyderabad from '../pages/Hyderabad/huderabad';
import Privacypolicy from '../pages/Privacy-Policy/privacy-policy';
import Pune from '../pages/Pune/pune';
import RefundPolicy from '../pages/Refund-Policy/refundPolicy';
import Services from '../pages/Services/services';
import SippingPolicy from '../pages/Shipping-Policy/shippingPolicy';
import TermsAndCondtions from '../pages/Terms-and-Conditions/termsAndCondtions';
import Visakhapatnam from '../pages/Visakhapatnam/visakhapatnam';
import NotFound from '../components/NotFound/NotFound';
import StanderedService from '../pages/Standerd-Service/StanderedService';
import AdvancedService from '../pages/Advanced-Service/AdvancedService';
import BasicService from '../pages/Basic-Service/BasicService';
import Login from '../pages/Login/Login';
import Admin from '../pages/Admin/Admin';
import AdminLayout from '../components/Layouts/AdminLayout';
import BasicServiceData from '../components/Admin-Components/BasicServiceData/BasicServiceData';
import StandredServiceData from '../components/Admin-Components/StandredServiceData/StandredServiceData';
import AdvancedServiceData from '../components/Admin-Components/AdvancedServiceData/AdvancedServiceData';
import MainLayout from '../components/Layouts/MainLayout';
import PrivateRoute from '../pages/Private/PrivatRoute';
import BasicSinglePage from '../components/Basic-Single-page/BasicSinglePage';
import StandredSinglePage from '../components/Standred-Single-page/StandredSinglePage';
import AdvancedSinglePage from '../components/Advanced-Single-page/AdvancedSinglePage';
import OrderPlaced from '../pages/OrderPlced/OrderPlaced';
import BasicServiceOrderPlaced from '../pages/OrderPlced/BasicServiceOrderPlaced';
import StandredserviceOrderPlced from '../pages/OrderPlced/StandredserviceOrderPlced';
import AdvancedServiceOrderPlaced from '../pages/OrderPlced/AdvancedServiceOrderPlaced';
import CycleRestorePage from '../pages/MetaRoute/CycleRestorePage';

const AllRoutes = () => {
  return (
    <Routes >

      <Route element={<MainLayout/>}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/chennai" element={<Chennai />} />
      <Route path="/coimbatore" element={<Coimbatore />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/delhi" element={<Delhi />} />
      <Route path="/hyderbad" element={<Hyderabad />} />
      <Route path="/privacy-policy" element={<Privacypolicy />} />
      <Route path="/pune" element={<Pune />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/services" element={<Services />} />
      <Route path="/shipping-Policy" element={<SippingPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondtions />} />
      <Route path="/visakhapatnam" element={<Visakhapatnam />} />
      <Route path="/basic-service" element={<BasicService />} />
      <Route path="/standred-service" element={<StanderedService />} />
      <Route path="/advanced-service" element={<AdvancedService />} />
      <Route path="/api/admin/login" element={<Login />} />
      <Route path='/orderplaced' element={<OrderPlaced/>} />
      <Route path='/orderplaced/basic-service' element={<BasicServiceOrderPlaced/>} />
      <Route path='/orderplaced/standred-service' element={<StandredserviceOrderPlced/>} />
      <Route path='/orderplaced/advanced-service' element={<AdvancedServiceOrderPlaced/>} />
      <Route path="/cycle-restore" component={CycleRestorePage} />

   </Route>


      <Route element={<AdminLayout />} >
        <Route path="basicservicedata" element={<PrivateRoute><BasicServiceData /></PrivateRoute>} />
        <Route path="standredservicedata" element={<PrivateRoute><StandredServiceData /></PrivateRoute>} />
        <Route path="advancedservicedata" element={<PrivateRoute><AdvancedServiceData /></PrivateRoute>} />
        <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
        <Route path='/basicservice/:id' element={<PrivateRoute><BasicSinglePage/></PrivateRoute>} />
        <Route path='/standredservice/:id' element={<PrivateRoute><StandredSinglePage/></PrivateRoute>} />
        <Route path='/advancedservice/:id' element={<PrivateRoute><AdvancedSinglePage/></PrivateRoute>} />
        <Route path="*" element={<NotFound />} />
      </Route>


    </Routes>
  );
};

export default AllRoutes;
