import React from 'react';
import { Box, Heading,Stack,Text,UnorderedList,ListItem } from '@chakra-ui/react'


const shippingPolicy = () => {
  return (
    <>
      <Box border={"2px solid transprent"} boxSizing='border-box' padding={"20px"}>
            <Box border={"2px solid transprent"}>
                <Box width={["","","90%","90%"]} margin={"auto"} height={"400px"}>
                <div style={{ position: "relative", height: "100%", width: "100%" }}>
  <img
    style={{
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "10px",
    }}
    src="https://images.unsplash.com/photo-1623216216626-f8bfd191552d?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    alt=""
  />
  <Box
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      backgroundColor="rgba(0, 0, 0, 0.5)" // Adjust opacity here
      borderRadius="10px"
      color="white"
      boxSizing="border-box"
      padding={["20px", "", "", "20px"]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading fontSize={["30px", "30px", "60x", "60px"]} textAlign="center">
      Our Shipping Commitment
      </Heading>
      <Text fontSize={["18px","18px","22px","22px"]} textAlign="center" mt={4}>
      Shipping Expert Technicians to Your Doorstep for Precision Service Excellence and Bringing Quality Service Right to Your Location.
      </Text>
    </Box>
</div>

                </Box>
            </Box>

            <Box textAlign={"left"} width={["","","","80%"]} m={"auto"} mt={"50px"}>
                  <Stack p={5}>
                  <Box p={[4, 8, 12, 16]} bg="gray.100" borderRadius="lg">
      <Heading as="h1" size="lg" mb={4}>
        Satisfaction Guarantee
      </Heading>
      <Text mb={4}>
        At Cycle Store, our utmost priority is your satisfaction with our
        services related to cycle sales, repairs, and accessories. We aim to
        provide reliable and high-quality solutions to meet your cycling needs.
      </Text>

      <Heading as="h2" size="md" mb={2}>
        Satisfaction Guarantee:
      </Heading>
      <Text mb={4}>
        We are committed to ensuring that every service you receive from us
        meets or exceeds your expectations. If, for any reason, you are not
        completely satisfied with the service you have received, please contact
        us immediately. We will do our best to resolve any issues promptly and
        to your satisfaction.
      </Text>

      <Heading as="h2" size="md" mb={2}>
        Eligibility for Refunds:
      </Heading>
      <UnorderedList mb={4}>
        <ListItem>You have been charged incorrectly.</ListItem>
        <ListItem>
          You have paid for a service that was not delivered as described.
        </ListItem>
        <ListItem>
          Our technicians were unable to complete the service due to unforeseen
          circumstances.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mb={2}>
        Requesting a Refund:
      </Heading>
      <Text mb={4}>
        To request a refund, please follow these steps:
      </Text>
      <UnorderedList mb={4}>
        <ListItem>
          Contact our customer support team within [number of days] days of the
          service date.
        </ListItem>
        <ListItem>
          Provide your name, contact information, service details, and a clear
          description of the issue.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mb={2}>
        Refund Process:
      </Heading>
      <Text mb={4}>
        Upon receiving your refund request, we will review the information
        provided and assess your eligibility for a refund. If your request is
        approved, we will initiate the refund process as follows:
      </Text>
      <UnorderedList mb={4}>
        <ListItem>
          For credit card payments: We will process the refund to the original
          payment method used for the transaction.
        </ListItem>
        <ListItem>
          For cash or check payments: We will issue a refund check or arrange
          for a refund via an agreed-upon method.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mb={2}>
        Non-Refundable Items:
      </Heading>
      <Text mb={4}>
        Certain items and situations may not be eligible for refunds. These
        include:
      </Text>
      <UnorderedList mb={4}>
        <ListItem>
          Any service charges incurred for work completed.
        </ListItem>
        <ListItem>
          Services delivered as described and to your satisfaction.
        </ListItem>
        <ListItem>
          Any damages or issues that arise after our service has been completed.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mb={2}>
        Contact Us:
      </Heading>
      <Text mb={4}>
        If you have any questions or concerns about our refund policy or wish to
        request a refund, please contact our customer support team at{" "}
        [customer support email] or [customer support phone number]. We are here
        to assist you and ensure your experience with Cycle Store is
        exceptional.
      </Text>

      <Heading as="h2" size="md" mb={2}>
        Policy Changes:
      </Heading>
      <Text mb={4}>
        We may update our refund policy from time to time to reflect changes in
        our services or to comply with legal requirements. Any updates to this
        policy will be posted on our website, and the date of the last revision
        will be indicated. Thank you for choosing Cycle Store for your cycling
        needs. We value your trust and strive to provide you with the best
        service possible.
      </Text>
    </Box>
                  </Stack>
            </Box>
      </Box>
    </>
  )
}

export default shippingPolicy