import * as types from "../app/actionTypes";
const init = {
  isLoading: false,
  isError: false,
  basicServiceData: [],
  standredServiceData: [],
  advancedServiceData: [],
  singleBasicService: [],
  singleStandredService: [],
  singleAdvancedService: [],
};

export const reducer = (oldState = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_BASIC_SERVICE_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
        basicServiceData: [],
      };
    case types.GET_BASIC_SERVICE_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        basicServiceData: payload,
      };
    case types.GET_BASIC_SERVICE_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
        basicServiceData: [],
      };

      case types.POST_BASIC_SERVICE_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
       
      };
    case types.POST_BASIC_SERVICE_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
       
      };
    case types.POST_BASIC_SERVICE_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
       
      };
    case types.GET_STANDRED_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
        standredServiceData: [],
      };
    case types.GET_STANDRED_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        standredServiceData: payload,
      };
    case types.GET_STANDRED_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
        standredServiceData: [],
      };

      case types.POST_STANDRED_SERVICE_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
       
      };
    case types.POST_STANDRED_SERVICE_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
       
      };
    case types.POST_STANDRED_SERVICE_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
       
      };
    case types.GET_ADVANCED_SERVICE_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
        advancedServiceData: [],
      };
    case types.GET_ADVANCED_SERVICE_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        advancedServiceData: payload,
      };
    case types.GET_ADVANCED_SERVICE_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
        advancedServiceData: [],
      };

      case types.POST_ADVANCED_SERVICE_DATA_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
       
      };
    case types.POST_ADVANCED_SERVICE_DATA_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
       
      };
    case types.POST_ADVANCED_SERVICE_DATA_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
       
      };
      
    case types.GET_SINGLE_BASIC_SERVICE_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
        singleBasicService: [],
      };
    case types.GET_SINGLE_BASIC_SERVICE_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        singleBasicService: payload,
      };
    case types.GET_SINGLE_BASIC_SERVICE_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
        singleBasiceService: [],
      };
    case types.GET_SINGLE_STANDRED_SERVICE_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
        singleStandredService: [],
      };
    case types.GET_SINGLE_STANDRED_SERVICE_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        singleStandredService: payload,
      };
    case types.GET_SINGLE_STANDRED_SERVICE_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
        singleStandredService: [],
      };

    case types.GET_SINGLE_ADVANCED_SERVICE_REQUEST:
      return {
        ...oldState,
        isLoading: true,
        isError: false,
        singleAdvancedService: [],
      };
    case types.GET_SINGLE_ADVANCED_SERVICE_SUCCESS:
      return {
        ...oldState,
        isLoading: false,
        isError: false,
        singleAdvancedService: payload,
      };
    case types.GET_SINGLE_ADVANCED_SERVICE_FAILURE:
      return {
        ...oldState,
        isLoading: false,
        isError: true,
        singleAdvancedService: [],
      };
    default:
      return oldState;
  }
};
