import { Box, Heading, Text, Image, Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const OrderPlaced = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" height="auto" bg="gray.50" mt={"100px"}>
        <Box border="2px solid transprent" width={["100%", "100%", "60%", "50%"]} padding={["10px","10px","70px","70px"]} borderRadius="10px" boxShadow="lg" bg="white">
          <Box textAlign="center">
            <Image
              borderRadius="full"
              boxSize="150px"
              src="https://cdn.create.vista.com/api/media/medium/74610723/stock-photo-check-mark-icon-on-rectangle-with-done-word?token="
              alt="Check Mark Icon"
              marginBottom="20px"
              margin="0 auto"
            />
            <Heading m={"10px 0px"} fontSize={["28px","28px","",""]} marginBottom="10px">Your Order is Confirmed!</Heading>
            <Text fontSize="lg" color="gray.600">Our technician will arrive promptly at your scheduled booking time</Text>
          </Box>
       <Link to={"/"}> <Button mt={"30px"} bgColor={"orange"}>Home</Button></Link>
        </Box>
      </Box>
    </>
  );
}

export default OrderPlaced;
