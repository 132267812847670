import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBasicData } from '../../../redux/app/action';
import { Table, TableContainer, Thead, Tbody, Tr, Th, Td, Button, Box } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { SyncLoader } from 'react-spinners';

const BasicServiceData = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.appReducer.basicServiceData);
  const role = localStorage.getItem('role');
  const city = localStorage.getItem('city');
  const loading = useSelector((state) => state.appReducer.isLoading);
  
  // State to track completed items
console.log("basic-service-store",store, city)

  useEffect(() => {
    let filters = { city };
    if (role === 'admin') {
      filters = {};
    }
    dispatch(getBasicData(filters))
      .then(res => {
         console.log("Fetched data:", res);
      })
      .catch(err => {
         console.error("Error fetching data:", err);
      });
  }, [dispatch, city, role]);

 

  return (
    <>
      {loading && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex={9999}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SyncLoader height={4} width={4} color="black" />
        </Box>
      )}
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>S.no</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Number</Th>
              <Th>PinCode</Th>
              <Td display={role === "technician" ? "none" : "block"} pl={"30px"}></Td>
            </Tr>
          </Thead>
          <Tbody>
            {store?.map((item, index) => (
              <Tr key={item.id}>
                <Td>{index + 1}</Td>
                <Td>{item.name}</Td>
                <Td>{item.email}</Td>
                <Td>{item.number}</Td>
                <Td>{item.pinCode}</Td>
                <Link to={`/basicservice/${item._id}`}>
                  <Td><Button color={"green"}>View</Button></Td>
                </Link>
                {/* <Td>
                  <Button display={role === "technician" ? "none" : "block"} color={"red"} onClick={() => { handleComplete(item._id) }}>Complete</Button>
                </Td> */}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BasicServiceData;
