import React from 'react'
import ServiceCards from '../../components/ServiceRelated/ServServiceCard'
import ServicePolicy from '../../components/ServiceRelated/ServicePolicy'
import OurFlatFrom from '../../components/ServiceRelated/OurFlatFrom'
import MailUs from '../../components/ServiceRelated/MailUs'
import Cards from '../../components/Header/Cards/Cards'
import ServiceCard from '../../components/ServiceRelated/ServServiceCard'
import MetaData from '../../components/CustomComp/MetaData'

const services = () => {
  return (
    <>
<MetaData
  title="Services - CycleRestore"
  description="CycleRestore offers a comprehensive range of services, including bicycle repairs, maintenance, and tune-ups, ensuring clients' bicycles are in top condition."
  keywords="bicycle repair services, bike maintenance, cycle tune-ups, CycleRestore services"
 />


     <ServiceCard/>
      <Cards/>
      <ServicePolicy/>
      <OurFlatFrom/>
      <MailUs/>
    </>
  )
}

export default services