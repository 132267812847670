import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { BiSupport } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";
import { FaTools } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa6";
import { MdWorkspacePremium } from "react-icons/md";
import { GiAutoRepair } from "react-icons/gi";
const ChooseUs = () => {
  return (
    <>
      <Box bgColor={"#fafafa"} border={"2px solid tranprent"} width={["99%", "99%", "96%", "96%"]} boxSizing='border-box' padding={["0px","0px","20px 30px","100px 230px"]} margin={"auto"} mt={"20px"} mb={"50px"}>
        <Box>
          <Heading p={["30px 0px", "30px 0px", "30px 0px", "10px 0px"]} textAlign="center" fontSize={["30px","30px","30px","50px"]} >Why Choose us ?</Heading>
          <SimpleGrid columns={[1, 1, 2, 3]} spacing={0}>
            <Box bg='#fafafa' textAlign={"center"} height='100px' borderRadius={"10px"} boxSizing="border-box" padding={"40px 35px"}>
              <Box display={"flex"} alignItems={"center"} justifyContent="left" gap={4} mb={"35px"}>
                <BiSupport size={"60px"} />
                <Heading fontWeight={"500"} textAlign="left" fontSize={["20px","20px","20px", "17px"]}>24/7 Support by Cycle Restore</Heading>
              </Box>
             
            </Box>
            <Box bg='#fafafa' textAlign="left" height='100px' borderRadius={"10px"} boxSizing="border-box" padding={"40px 35px"}>
              <Box display={"flex"} alignItems={"center"} justifyContent="left" gap={4} mb={"35px"}>
                <MdMiscellaneousServices size={"60px"} />
                <Heading fontWeight={"500"} textAlign="left" fontSize={["20px","20px","20px", "17px"]}>Trained Service Partners</Heading>
              </Box>
             
            </Box>
            <Box bg='#fafafa' textAlign="left" height='100px' borderRadius={"10px"} boxSizing="border-box" padding={"40px 35px"}>
              <Box display={"flex"} alignItems={"center"} justifyContent="left" gap={4} mb={"35px"}>
                <FaTools size={"60px"} />
                <Heading fontWeight={"500"} textAlign="left" fontSize={["20px","20px","20px", "17px"]}>Standardized Tools and Products</Heading>
              </Box>
            
            </Box>
            <Box bg='#fafafa' textAlign="left" height='100px' borderRadius={"10px"} boxSizing="border-box" padding={"40px 35px"}>
              <Box display={"flex"} alignItems={"center"} justifyContent="left" gap={4} mb={"35px"}>
                <FaClipboardCheck size={"60px"} />
                <Heading fontWeight={"500"} textAlign="left" fontSize={["20px","20px","20px", "17px"]}>Strict Background Check</Heading>
              </Box>
              
            </Box>
            <Box bg='#fafafa' textAlign="left" height='100px' borderRadius={"10px"} boxSizing="border-box" padding={"40px 35px"}>
              <Box display={"flex"} alignItems={"center"} justifyContent="left" gap={4} mb={"35px"}>
                <GiAutoRepair size={"60px"} />
                <Heading fontWeight={"500"} textAlign="left" fontSize={["20px","20px","20px", "17px"]}>Trusted Repairs</Heading>
              </Box>
              
            </Box>
            <Box bg='#fafafa' textAlign="left" height='100px' borderRadius={"10px"} boxSizing="border-box" padding={"40px 35px"}>
              <Box display={"flex"} alignItems={"center"} justifyContent="left" gap={4} mb={"35px"}>
                <MdWorkspacePremium size={"60px"} />
                <Heading fontWeight={"500"} textAlign="left" fontSize={["20px","20px","20px", "17px"]}>Premium Service</Heading>
              </Box>
              
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}

export default ChooseUs
