export const visakhapatnamPincodes = [
    "500004",
    "500045",
    "500007",
    "500013",
    "500004",
    "500015",
    "500044",
    "500040",
    "500020",
    "500048",
    "500034",
    "500027",
    "500004",
    "500012",
    "500016",
    "500003",
    "500018",
    "500080",
    "500039",
    "500013",
    "500022",
    "500005",
    "500081",
    "500060",
    "500062",
    "500062",
    "500018",
    "500018",
    "500015",
    "500001",
    "500080",
    "500015",
    "500015",
    "500068",
    "500052",
    "500029",
    "500001",
    "500051",
    "500035",
    "500028",
    "500016",
    "500016",
    "500008",
    "500002",
    "500013",
    "500001",
    "500048",
    "500076",
    "500082",
    "500007",
    "500040",
    "500015",
    "500007",
    "500015",
    "500079",
    "500085",
    "500033",
    "500008",
    "500058",
    "500079",
    "500006",
    "500077",
    "500005",
    "500004",
    "500062",
    "500003",
    "500015",
    "500067",
    "500015",
    "500015",
    "500062",
    "500074",
    "500063",
    "500015",
    "500017",
    "500017",
    "500008",
    "500003",
    "500081",
    "500036",
    "500076",
    "500005",
    "500006",
    "500002",
    "500018",
    "500040",
    "500015",
    "500020",
    "500020",
    "500044",
    "500008",
    "500062",
    "500029",
    "500026",
    "500060",
    "500063",
    "500044",
    "500007",
    "500027",
    "500015",
    "500036",
    "500029",
    "500012",
    "500036",
    "500060",
    "500025",
    "500004",
    "500005",
    "500004",
    "500003",
    "500015",
    "500016",
    "500095",
    "500065",
    "500069",
    "500071",
    "500041",
    "500016",
    "500095",
    "500059",
    "500059",
    "500017",
    "500008",
    "500018",
    "500018",
    "500038",
    "500059",
    "500035",
    "500003",
    "500013",
    "500065",
    "500028",
    "500004",
    "500061",
    "500076",
    "500082",
    "500033",
    "500073",
    "500013",
    "500036",
    "500001",
    "500095",
    "500027",
    "500015",
    "500039",
    "500052",
    "500018",
    "500064",
    "500002",
    "500007",
    "500015",
    "500008",
    "500039",
    "500053",
    "500070",
    "500079",
    "500070",
    "500038",
    "500004",
    "500044",
    "500057",
    "500045",
    "502001",
    "502032",
    "502032",
  ];
