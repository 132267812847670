import React from 'react'
import { Box } from '@chakra-ui/react'
import BasicServiceData from '../../components/Admin-Components/BasicServiceData/BasicServiceData'


const Admin = () => {
  return (
    <>
    <Box >
        <BasicServiceData/> 
    </Box>
    </>
  )
}

export default Admin