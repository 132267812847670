import { Box, Heading, SimpleGrid,Text } from '@chakra-ui/react'
import React from 'react'
import { RiCustomerService2Line } from "react-icons/ri";
import { GrServices } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";

const ServicePolicy = () => {
  return (
    <>
        <Box borderRadius={"10px"} bgColor={"#FCFCFC"} border={"2px solid transprent"} width={["90%","90%","80%","90%"]} margin={"auto"} boxSizing='border-box'padding={["","","60px","60px"]}>
            <Box m={["30px","","","30px"]}>
                    <Heading fontSize={["50px","","","70px"]}>Our Promise</Heading>
                    <Text fontSize={["16px","","","19px"]}>Your Satisfaction, Our Commitment</Text>
            </Box>
        <SimpleGrid columns={[1,1,4,4]} spacing={["10px","10px","10px","10px"]}>
            <Box boxSizing='border-box' padding={"60px 30px"} bg='tomato' height='350px' borderRadius={"10px"} bgColor={"white"}>
                <Text fontWeight={"300"} fontSize={["30px","","","28px"]}>Top-notch bike services ensure your complete satisfaction, safety, and peace of mind always.</Text>
            </Box>
            <Box bg='tomato' height='350px' borderRadius={"10px"} bgColor={"white"} boxSizing='border-box' padding={"30px"}>
                <Box textAlign={"left"}>
                    <RiCustomerService2Line size={"50px"}/>
                    <Heading mt={"10px"} fontSize={["30px","30px","35px","35px"]} fontWeight={"500"}>Quality ​Spare Parts</Heading>
                    <Text mt={"20px"} fontSize={"18px"}>Enjoy peace of mind with our 48-hour service warranty for your bicycle.</Text>
                </Box>     
            </Box>

            <Box bg='tomato' height='350px' borderRadius={"10px"} bgColor={"white"} boxSizing='border-box' padding={"30px"}>
                <Box textAlign={"left"}>
                    <GrServices size={"50px"}/>
                    <Heading mt={"10px"} fontSize={["30px","30px","35px","35px"]} fontWeight={"500"}>48 Hour Service ​Warranty</Heading>
                    <Text mt={"20px"} fontSize={"18px"}>Enjoy peace of mind with our 48-hour service warranty for your bicycle.</Text>
                </Box>     
            </Box>

            <Box bg='tomato' height='350px' borderRadius={"10px"} bgColor={"white"} boxSizing='border-box' padding={"30px"}>
                <Box textAlign={"left"}>
                    <FaRegCircleUser size={"50px"}/>
                    <Heading mt={"10px"} fontSize={["30px","30px","35px","35px"]} fontWeight={"500"}>Expert ​Technicians</Heading>
                    <Text mt={"20px"} fontSize={"18px"}>Enjoy peace of mind with our 48-hour service warranty for your bicycle.</Text>
                </Box>     
            </Box>
        </SimpleGrid>
        </Box>
    </>
  )
}

export default ServicePolicy