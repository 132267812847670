import {
  Box, Heading, Input, Text, Radio, RadioGroup, Select, Stack, Textarea, Button,Checkbox,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getAdvancedData, getStandredData, postAdvancedServiceData} from '../../redux/app/action';
import { Link, useNavigate } from 'react-router-dom';
import {  SyncLoader } from 'react-spinners';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { visakhapatnamPincodes } from '../Codes/vskpCodes';
import { radioButtonData } from '../Codes/radioTimings';
import TermModal from './TermsModal';
const AdvancedServiceForm = () => {
  

  const dispatch = useDispatch();

  const init = {
    name: "",
    email: "",
    brand: "",
    number: "",
    city: "",
    pinCode: "",
    date: "",
    typeOfCycle: "",
    timings: "",
    address: "",
    termsAndConditions: "",
  };

  
  const [formData, setFormData] = useState(init);
  const [errors, setErrors] = useState(init);
  const navigate = useNavigate();
  const loading = useSelector((state)=>state.appReducer.isLoading);
  const AdvancedData = useSelector((state)=>state.appReducer.advancedServiceData)
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleChange = (e) => {
    console.log("advanced data", AdvancedData)
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (value) => {
    setFormData({
      ...formData,
      timings: value,
    });
  };

  const handleCheckBoxChange = (e) => {
    setFormData({
      ...formData,
      termsAndConditions: e.target.checked,
    });
  };

 

  const handleCityChange = (e) => {
    setFormData({
      ...formData,
      city: e.target.value,
    });
  };

  const handleCycleChange = (e) => {
    setFormData({
      ...formData,
      typeOfCycle: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Please enter name";
    }


    if (!formData.brand) {
      newErrors.brand = "Please enter a brand";
    }

    if (!formData.number) {
      newErrors.number = "Please enter number";
    } else if (formData.number.length !== 10) {
      newErrors.number = "Number must be exactly 10 digits";
    }

    if (!formData.city) {
      newErrors.city = "Please enter your city";
    }

    if (!formData.pinCode) {
      newErrors.pinCode = "Please enter pin code";
    } else if (isNaN(formData.pinCode)) {
      newErrors.pinCode = "Pin code must be a number";
    }

    if (!formData.date) {
      newErrors.date = "Please enter date";
    }

    if (!formData.typeOfCycle) {
      newErrors.typeOfCycle = "Please select type of cycle";
    }

    if (!formData.timings) {
      newErrors.timings = "Please select the timings";
    }

    if (!formData.address) {
      newErrors.address = "Please enter your address";
    }

    if(!formData.termsAndConditions){
      newErrors.termsAndConditions = "Please accept terms and conditions";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      dispatch(postAdvancedServiceData(formData))
        .then(res => {
          console.log("Standred Service res", res)
          if(res?.payload?.message === "Post successful"){
            if(res.payload?.data?._id){
              localStorage.setItem("advancedBookingId",JSON.stringify(res.payload?.data?._id) )
            }
      
          navigate("/orderplaced/advanced-service")
       
           }
        }); 
    }
  };

  useEffect(()=>{
    dispatch(getAdvancedData())
    .then(res=>{
      // console.log("advanced",res)
    })
  },[dispatch])

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split("T")[0];
  };

  // Variable to hold tomorrow's date
  const tomorrowDate = getTomorrowDate();

  return (
    <>
        {loading && (
   <Box
   position="fixed"
   top="50%"
   left="50%"
   transform="translate(-50%, -50%)"
   zIndex={9999}
   width="100vw" // Full width of the viewport
   height="100vh" // Full height of the viewport
   display="flex"
   justifyContent="center"
   alignItems="center"
   backgroundColor="rgba(0, 0, 0, 0.5)" // Optional: a semi-transparent background
   pointerEvents="none" // Ensures it doesn't block interactions with underlying components
 >
          <SyncLoader height={4} width={4} color="black" />
        </Box>
      )}
      <Box width={"100%"} border={"2px solid transparent"} height={"auto"}>
        <Box border={"2px solid transparent"} width={["95%", "95%", "80%", "80%"]} margin={"auto"}>
          <Box border={"2px solid transparent"} display={["block", "block", "flex", "flex"]} gap={"10px"}>
            <Box border={"2px solid transparent"} width={["", "", "", "60%"]} boxSizing='border-box' p={["", "", "", "0px 150px"]}>
              <Heading fontSize={"50px"} fontWeight={"300"} textAlign={"left"} m={'20px 0px'}>Advanced Details</Heading>
              <form onSubmit={handleSubmit}>
                <Stack gap={"30px"}>
                 
                  <Box mb="10px">

                  <Select
                        border={
                          errors.city ? "2px solid red" : "1px solid lightgrey"
                        }
                        placeholder="City"
                        name="city"
                        onChange={handleCityChange}
                      >
                        {/* <option value="visakhapatnam">Visakhapatnam</option>
                        <option value="vijayawada">Vijayawada</option>
                        <option value="guntur">Guntur</option> */}
                        <option value="hyderabad">Hyderabad</option>

                        {/* <option value="nellore">Nellore</option>
                        <option value="kurnool">Kurnool</option>
                        <option value="rajahmundary">Rajahmundary</option>
                        <option value="tirupathi">Tirupathi</option>
                        <option value="kakinada">Kakinada</option>
                        <option value="ananthpur">Ananthpur</option>
                        <option value="eluru">Eluru</option>
                        <option value="pune">Pune</option> */}
                      </Select>
                      {errors.city && <Text color="red">{errors.city}</Text>}
                   
                  </Box> <Box>
                    <Input
                      border={
                        errors.pinCode ? "2px solid red" : "1px solid lightgrey"
                      }
                      value={formData.pinCode}
                      name="pinCode"
                      onChange={handleChange}
                      type="number"
                      placeholder="Pin Code"
                    />
                    {formData.pinCode.length > 0 &&
                      !visakhapatnamPincodes.includes(formData.pinCode) && (
                        <Text color={"red"}>We don't deliver to this location.</Text>
                      )}
                    {errors.pinCode && (
                      <Text color="red">{errors.pinCode}</Text>
                    )}
                  </Box>



                  <Box display={["", "", "", "flex"]} gap={"30px"} mb="10px">
                    <Box width={["100%", "100%", "48%", "48%"]}>
                      <Input
                        border={
                          errors.brand ? "2px solid red" : "1px solid lightgrey"
                        }
                        value={formData.brand}
                        name="brand"
                        onChange={handleChange}
                        type="text"
                        placeholder="Brand"
                      />
                      {errors.brand && <Text color="red">{errors.brand}</Text>}
                    </Box>{" "}
                    <br />
                    <br />
                    <Box width={["100%", "100%", "48%", "48%"]}>

                    <Select
                        border={
                          errors.typeOfCycle
                            ? "2px solid red"
                            : "1px solid lightgrey"
                        }
                        placeholder="Type of cycle"
                        name="typeOfCycle"
                        onChange={handleCycleChange}
                      >
                        <option value="Road Cycle">Road Cycle</option>
                        <option value="Non Geared">Non Geared</option>
                        <option value="Geared">Geared</option>
                        <option value="Electric cycle">Electric cycle</option>
                      </Select>
                      {errors.typeOfCycle && (
                        <Text color="red">{errors.typeOfCycle}</Text>
                      )}
                      
                    </Box>
                  </Box>
                  <Box display={["", "", "", "flex"]} gap={"30px"} mb="10px">

                    <Box width={["100%", "100%", "48%", "48%"]}>
                    <Input
                      border={
                        errors.name ? "2px solid red" : "1px solid lightgrey"
                      }
                      value={formData.name}
                      name="name"
                      onChange={handleChange}
                      type="text"
                      placeholder="Name"
                    />
                    {errors.name && <Text color="red">{errors.name}</Text>}


                    </Box>
                    <br /> <br />
                    <Box width={["100%", "100%", "48%", "48%"]} mb="10px">    
                    <Input
                        border={
                          errors.number
                            ? "2px solid red"
                            : "1px solid lightgrey"
                        }
                        value={formData.number}
                        name="number"
                        onChange={handleChange}
                        type="tel"
                        placeholder="Number"
                      />
                      {errors.number && (
                        <Text color="red">{errors.number}</Text>
                      )}


                    
                    </Box>
                  </Box>
                  <Box display={["", "", "", "flex"]} gap={"30px"} mb="10px">
                    <Box width={["100%", "100%", "48%", "48%"]}>
                      <Input
                        border={
                          errors.date ? "2px solid red" : "1px solid lightgrey"
                        }
                        value={formData.date}
                        name="date"
                        onChange={handleChange}
                        type="date"
                        placeholder="Date"
                        min={tomorrowDate}
                      />
                      {errors.date && <Text color="red">{errors.date}</Text>}
                    </Box>
                    <br />
                    <br />
                    <Box width={["100%", "100%", "48%", "48%"]}>
                  
                    <Input
                        border={
                          errors.email ? "2px solid red" : "1px solid lightgrey"
                        }
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                        type="email"
                        placeholder="Email  (Optional)"
                      />
                      {errors.email && <Text color="red">{errors.email}</Text>}



                    </Box>
                  </Box>
                  <Box mb="10px">
                    <Text textAlign={"left"} fontSize={"20px"}m={"10px"} fontWeight={700} ml={"20px"}>Select Timings</Text>
                    <RadioGroup name="timings" onChange={handleRadioChange}>
                      <Stack direction="column" gap={"20px"}>
                        
                       {
                        radioButtonData?.map(button=>(
                          
                          <Radio
                          isDisabled={
                           
                            AdvancedData?.filter((item) => {
                              if (
                                item.timings === button.value &&
                                item.date.split("T")[0] === formData.date 
                               // console.log(button.value.split("-")[0], hours())
                              )
                                return item;
                            }).length > 0  
                            
                          }
                          value={button.value}
                        >
                          {button.value}
                        </Radio>
                        ))
                       }
                      </Stack>
                    </RadioGroup>
                    {errors.timings && (
                      <Text color="red">{errors.timings}</Text>
                    )}
                  </Box>
                  <Box mb="10px">
                    <Textarea
                      border={
                        errors.address ? "2px solid red" : "1px solid lightgrey"
                      }
                      value={formData.address}
                      name="address"
                      onChange={handleChange}
                      placeholder="Please enter your address"
                    />
                    {errors.address && (
                      <Text color="red">{errors.address}</Text>
                    )}
                     {/* <Text ><span>please specify if any issue</span></Text> */}
                  </Box>
                  <Box>
                    <Checkbox
                      value={formData.termsAndConditions}
                      onChange={handleCheckBoxChange}
                    >
                      Please Accept <TermModal isOpen={isOpen} onClose={onClose} />
                    </Checkbox>
                    {errors.termsAndConditions && (
                      <Text color="red">{errors.termsAndConditions}</Text>
                    )}
                  </Box>
                </Stack>
                <Box>
                  <Button
                    padding={[
                      "30px 150px",
                      "30px 150px",
                      "30px 220px",
                      "30px 220px",
                    ]}
                    type="submit"
                    bgColor={"black"}
                    color={"white"}
                    mb={["35px", "35px", "0px", "0px"]}
                    mt={["35px", "35px", "25px", "25px"]}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
            <Box border={"2px solid transparent"} width={["", "", "", "40%"]} boxSizing='border-box' padding={["", "", "", "30px 0px"]}>
              <Box textAlign={"left"} border={"2px solid transparent"}>
                <Text fontSize={"25px"} fontWeight={"500"}>Kindly note:</Text>
                <Text fontWeight={"200"} fontSize={"17px"}>Any new spares used will be charged extra as per MRP*. <br /> *Top up/bleeding of hydraulic disc brake will be charged extra. <br />Labour cost for change of spares is included in the service cost. <br />No additional visit charge</Text>
              </Box>
              <Box textAlign={"left"} p={["", "", "", "20px"]}>
                <Heading fontSize={"50px"} fontWeight={"300"} m={["", "", "", "20px 0px"]}>Advanced Service</Heading>
                <hr style={{ border: "1px solid grey", margin: "10px 0px" }} />
                <Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Comprehensive cycle inspection</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Chain and cable lubrication</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Cleaning of gears, chain, cranks</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Headset and handlebar alignment</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Tightening of all screws and bolts</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Brake tuning and adjustment</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Gear tuning and adjustment</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Puncture repairs and tire inflation</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Dismantling of wheels, brakes, and chain</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Degreasing of chain, gears, and cranks (drivetrain)</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Wheel truing for precise alignment</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Full cycle wash with soap and water</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Front and rear hub checkup and lubrication</Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Headset checkup and lubrication</Text>
                  </Box>

                  <Box display={"flex"} alignItems={"center"} mb={["", "", "", "15px"]}>
                     <CheckCircleOutlineOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} />
                    <Text fontSize={"15px"} textAlign="left">Thorough cleaning of the cycle with cleanser and water</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdvancedServiceForm;
