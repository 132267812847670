import { Box, Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

const AdminLayout = () => {

  const navigate  = useNavigate();

  const logOutHandler =()=>{
    localStorage.removeItem("token");
      navigate("/api/admin/login")
  }

  
  return (
    <>
        <Box  border={"2px solid transprent"}>
            <Box  border={"2px solid transprent"} display={["","","flex","flex"]} boxSizing="border-box" padding={["","","0p 50px","0px 50px"]} gap={["20px","20px","50px","50px"]}>
                <Box  boxSizing='border-box' p={["20px 0px","20px 10px","0px 30px","0px 30px"]} border={"2px solid transprent"} width={["80%","80%","20%","20%"]} mt={["10px","10px","200px","200px"]} m={"auto"} display={["","","",""]}>
                    {/* <Heading fontWeight={"400"} m={["30px 0px","30px 0px","0px 0px","0px 0px"]} fontSize={"40px"}>Admin Dashboard</Heading> */}
                   <Stack gap={"20px"}>
                    <Link to={"/"}> <Button height={["40px","40px","70px","70px"]} width={["100%","100%","100%","100%"]}>Home</Button></Link>
                    <Link to={"/basicservicedata"}><Button height={["40px","40px","70px","70px"]} width={["100%","100%","100%","100%"]}>Basic Service</Button></Link>
                    <Link to={"/standredservicedata"}><Button height={["40px","40px","70px","70px"]} width={["100%","100%","100%","100%"]}>Standered Service</Button></Link>
                    <Link to={"/advancedservicedata"}><Button height={["40px","40px","70px","70px"]} width={["100%","100%","100%","100%"]}>Advanced Service</Button></Link>
                    <Button onClick={logOutHandler} height={["40px","40px","70px","70px"]} width={["","","","100%"]}>Log out</Button>
                   </Stack>
                </Box>
                <Box height={["","","","98vh"]} boxSizing='border-box' overflow={"scroll"} padding={["","","","50px 0px"]} border={"2px solid transprent"}width={["","","80%","80%"]}><Outlet/></Box>
            </Box>
        </Box>
       
    </>
  )
}

export default AdminLayout